<template>
  <div>
    <b-modal id="modalPassword" class="model1" ref="modalPassword" centered hide-footer hide-header title-sr-only:false>
      <div @click="hideModal" style="width:10px;height:10px;position:absolute;top:3px;right:10px;cursor:pointer">
        <img src="../../assets/img/krest.svg" alt="">
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <h2 class="PTextB">{{ $t('modalPassword.h2') }}</h2>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <p class="P1Text">{{ $t('modalPassword.p') }}</p>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button class="greenbtn" @click="auth">{{ $t('modalPassword.btn') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex"

export default {
  data(){
    return{
      msg: null
    }
  },
  methods: {
    ...mapActions(
      [
        'buyProxy',
        'fetchAccounts',
      ]
    ),
    bay_proxy() {
      this.buyProxy()
        .then((response) => {
          if (response.data.code == 200) {
            this.hideModal()
            this.fetchAccounts()
          }
        })
        .catch((error) => {
          this.msg = this.$i18n.t(error.response.data.msg)
        })
    },
    auth(){
      this.$router.push('/authorization')
    },
    hideModal() {
      this.$refs['modalPassword'].hide()
    },
  }
}
</script>