<template>
  <div>
    <b-modal
      id="modal-tarif"
      class="model3"
      ref="modal-tarif"
      centered
      hide-footer
      hide-header
      title-sr-only:false
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <span v-if="getBalance >= getPrice">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modalTarif.h2") }}</h2>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="P1Text" v-html="$t('modalTarif.p1')"></p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="connect()">{{
            $t("modalTarif.connect_to_tg")
          }}</b-button>
        </div>
      </span>
      <span v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modalTarif.h2") }}</h2>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="P1Text" v-html="$t('modal3.p3')"></p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="toBalance()">{{
            $t("modal3.btn_balance")
          }}</b-button>
        </div>
      </span>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    // this.fetchPlans()
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-tarif") {
        if (this.getBalance < 1000) {
          // this.$router.push('/balance')
        }
      }
    });
  },
  computed: {
    ...mapGetters(["getPrice", "getBalance"]),
    price: {
      get() {
        return this.getPrice;
      },
    },
  },
  methods: {
    ...mapActions(["fetchBuyPlan", "setPaidPlanId"]),
    toBalance() {
      this.$router.push('/balance')
    },
    connect() {
      if (localStorage.getItem("locale") == "En") {
        try {
          window.open("https://t.me/ecompayhelp", "_blank").focus();
        } catch {
          window.open("https://t.me/ecompayhelp").focus();
        }
      } else {
        try {
          window.open("https://t.me/ecompayhelp", "_blank").focus();
        } catch {
          window.open("https://t.me/ecompayhelp").focus();
        }
      }
    },
    account() {
      this.fetchBuyPlan();
    },
    hideModal() {
      this.$refs["modal-tarif"].hide();
    },
  },
};
</script>
