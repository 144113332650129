<template>
  <div>
    <b-modal
      id="modal-personal-data"
      class="model1"
      ref="modal-personal-data"
      centered
      hide-footer
      hide-header
      title-sr-only:false
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <span :key="keyPartner">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">Личная информация</h2>
          <!--<div class="BalBar1">$2</div>-->
        </div>
        <validation-observer ref="observer" v-slot="{ handleSubmit, valid }">
          <b-form @submit.prevent="handleSubmit(onSubmit(valid))"
            ><validation-provider
              name="Обращение"
              :rules="{
                required: true,
                min: 2,
                max: 2,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Обращение</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="title"
                  placeholder="Mr./Ms."
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Имя"
              :rules="{
                required: true,
                min: 3,
                max: 250,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Имя</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.firstname"
                  placeholder="Alex"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <b-form-group content-cols="12" class="mb-3">
              <p class="small text-muted mb-1">Второе имя (при наличии)</p>
              <b-form-input
                type="text"
                class="input w-100"
                v-model="secondname"
                placeholder="John"
              >
              </b-form-input>
            </b-form-group>
            <validation-provider
              name="Фамилия"
              :rules="{
                required: true,
                min: 3,
                max: 250,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Фамилия</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.surename"
                  placeholder="Johnson"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Дата рождения"
              :rules="{
                required: true,
                min: 10,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Дата рождения</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.dateofbirth"
                  placeholder="01.03.1990"
                  :state="getValidationState(validationContext)"
                  v-mask="'##.##.####'"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Гражданство"
              :rules="{
                required: true,
                min: 3,
                max: 250,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Гражданство</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.nationality"
                  placeholder="Russia / Ukraine / Kazakhstan …"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Страна проживания"
              :rules="{
                required: true,
                min: 3,
                max: 250,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Страна проживания</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.country"
                  placeholder="Russia / Ukraine / Kazakhstan …"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Профессия"
              :rules="{
                required: true,
                min: 3,
                max: 250,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Профессия</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.occupation"
                  placeholder="Entrepreneur"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="PTextB">Адрес проживания</h2>
              <!--<div class="BalBar1">$2</div>-->
            </div>
            <validation-provider
              name="Квартира"
              :rules="{
                required: true,
                min: 1,
                max: 5,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Квартира</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.flat"
                  placeholder="1"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Дом"
              :rules="{
                required: true,
                min: 1,
                max: 5,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Дом</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.building"
                  placeholder="7"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Улица"
              :rules="{
                required: true,
                min: 5,
                max: 100,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Улица</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.street"
                  placeholder="Lenina"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Город"
              :rules="{
                required: true,
                min: 1,
                max: 100,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Город</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.city"
                  placeholder="Moscow"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Индекс"
              :rules="{
                required: true,
                min: 3,
                max: 100,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Индекс</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.zip"
                  placeholder="111000"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name="Страна"
              :rules="{
                required: true,
                min: 3,
                max: 100,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Страна</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.country_live"
                  :state="getValidationState(validationContext)"
                  placeholder="Russia"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="PTextB">Безопасность</h2>
              <!--<div class="BalBar1">$2</div>-->
            </div>
            <validation-provider
              name="Три первые буквы имени отца "
              :rules="{
                required: true,
                min: 3,
                max: 3,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">Три первые буквы имени отца</p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.first_three_name_father"
                  placeholder="Ale"
                  v-mask="'XXX'"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name=" Три первые буквы города (места) рождения "
              :rules="{
                required: true,
                min: 3,
                max: 3,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">
                  Три первые буквы города (места) рождения
                </p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-model="getPartnerFromIndex.first_three_name_birth"
                  placeholder="Mos"
                  v-mask="'XXX'"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <validation-provider
              name=" "
              :rules="{
                required: true,
                min: 3,
                max: 3,
              }"
              v-slot="validationContext"
            >
              <b-form-group content-cols="12" class="mb-3">
                <p class="small text-muted mb-1">
                  Последние три цифры в паспорте
                </p>
                <b-form-input
                  type="text"
                  class="input w-100"
                  v-mask="'###'"
                  v-model="getPartnerFromIndex.last_three_passport"
                  placeholder="123"
                  :state="getValidationState(validationContext)"
                >
                </b-form-input>
              </b-form-group>
              <div class="invalid-err" v-if="btnSubmit">
                {{ validationContext.errors[0] }}
              </div>
            </validation-provider>
            <div class="d-flex justify-content-center mt-2">
              <b-button type="submit" class="greenbtn">Сохранить</b-button>
            </div>
          </b-form>
        </validation-observer>
      </span>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    msg: null,
    password: null,
    accountData: null,
    valid_pwd: false,
    loading: true,
    btnSubmit: false,
  }),

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },

  methods: {
    ...mapActions(["setPartnerDataFromIndex", "setKeyPartner"]),

    hideModal() {
      this.setKeyPartner();
      this.$refs["modal-personal-data"].hide();
    },

    onSubmit(valid) {
      this.btnSubmit = true;
      if (typeof valid == "boolean") {
        if (valid) {
          this.hideModal();
        }
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return this.btnSubmit ? (dirty || validated ? valid : null) : null;
    },
  },

  computed: {
    ...mapGetters(["getPartnerFromIndex", "getKeyPartner"]),

    ...Object.fromEntries(
      [
        ["title", "title"],
        ["firstname", "firstname"],
        ["secondname", "secondname"],
        ["surename", "surename"],
        ["dateofbirth", "dateofbirth"],
        ["nationality", "nationality"],
        ["country", "country"],
        ["occupation", "occupation"],
        ["flat", "flat"],
        ["building", "building"],
        ["street", "street"],
        ["city", "city"],
        ["zip", "zip"],
        ["first_three_name_father", "first_three_name_father"],
        ["first_three_name_birth", "first_three_name_birth"],
        ["last_three_passport", "last_three_passport"],
      ].map(([key, value]) => [
        key,
        {
          get() {
            return this.getPartnerFromIndex[value] || "";
          },
          set(val) {
            const data = { key: value, value: val };
            this.setPartnerDataFromIndex(data);
          },
        },
      ])
    ),
  },
};
</script>
