<template>
  <div style="min-height: 99vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card class="cardB1" no-body>
          <b-row>
            <b-col md="6">
              <h1 class="H1Heading">{{ $t("balance.h1") }}</h1>
              <p class="PText mt-4">{{ $t("balance.p1") }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card no-body class="cardB2">
          <div class="d-flex">
            <h2 class="PTextB">{{ $t("balance.h2") }}</h2>
          </div>
          <b-row>
            <b-col xl="5" lg="6">
              <p class="P1Text mt-3">{{ $t("balance.p2") }}</p>
              <div class="divcardB2">
                <div @click="platezhka(index)" v-for="(item, index) in getGeneralMethods()" :key="index"
                  class="cardB2div" :class="[system == index ? 'cardB2div1' : '']">
                  <img :src="'https://lk.easypayments.online/' + item.cover" class="m-auto cardB1img1" alt="" />
                </div>
              </div>
              <div v-for="(item, index) in getGeneralMethods()" :key="index">
                <div v-if="system == index" class="P1Text mt-3" style="font-size: 16px" v-html="item.description"></div>
              </div>
            </b-col>
            <b-col xl="7" lg="6">
              <b-form>
                <b-row>
                  <b-col xl="8">
                    <p class="P1Text mt-3">{{ $t("balance.p3") }}</p>
                    <b-form-group>
                      <b-form-select class="input mt-3 w-100" v-model="MethodCode"
                        :options="getPaymentsMethods()"></b-form-select>
                    </b-form-group>
                    <p class="P1Text mt-4">{{ $t("balance.p4") }}</p>
                    <b-form-group>
                      <b-form-input class="input mt-3" v-model="amount" placeholder="0" type="number"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col xl="4" class="d-flex flex-column">
                    <p class="P1Text mt-3 ms-auto">{{ $t("balance.p5") }}:</p>
                    <div class="d-flex mt-3 mt-xl-auto mb-3">
                      <div class="d-flex flex-column mt-xl-3 mt-xl-auto ms-auto align-items-center">
                        <p class="cena ms-auto order-xl-1 order-2 mb-0">
                          ${{ getUsd }}
                        </p>
                        <p v-if="$i18n.locale == 'ru'" class="SText me-auto order-xl-2 order-1 mb-0">
                          ₽: {{ getEur }}
                        </p>
                      </div>
                    </div>
                    <b-button class="greenbtn w-100 mt-3 mt-md-0" @click="fetchСreate()">{{ $t("balance.btn1")
                      }}</b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <insr1 class="MBcard"></insr1>
    <ModalAccountInfo></ModalAccountInfo>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import insr1 from "../components/main/insr1.vue";
import ModalAccountInfo from "../components/modal/modalAccountInfo.vue";
export default {
  components: {
    insr1,
    ModalAccountInfo,
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.balance"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/balance",
        },
      ],
    };
  },
  data() {
    return {
      page: 1,
      system: 0,
    };
  },
  mounted() {
    this.fetchAccountsAsync().then((response) => {
      if (!localStorage.getItem("AccountInfoAccept")) {
        if (this.getAccounts().length > 0) {
          this.$root.$emit(
            "bv::show::modal",
            "modalAccountInfo",
            "modalAccountInfo"
          );
        }
      }
    });
    try {
      this.fetchGeneralMethods();
      this.setIndex(0);
      this.fetchPaymentsMethods(0);
      // this.setGeneralCode(0)
    } catch (error) {
      console.error(error);
    }
    // this.setId('24')
    // this.setIcon('divcardH47')
    this.setCAT4("start");
    this.setSlugPage("start");
    this.fetchCategories();
    this.fetchCategories2();
  },
  computed: {
    ...mapGetters([
      "getEur",
      "getUsd",
      "getAmount",
      "getName",
      "getMethodCode",
      "",
    ]),
    amount: {
      get() {
        return this.getAmount;
      },
      set(amount) {

        console.log("amount", amount);
        this.setAmount(amount);
      },
    },
    MethodCode: {
      get() {
        return this.getMethodCode;
      },
      set(value) {
        this.setMethodCode(value);
      },
    },
  },
  methods: {
    platezhka(index) {
      this.system = index;
      this.setIndex(index);
      this.setGeneralCode(index);
      this.fetchPaymentsMethods();
      this.fetchConvert();
    },
    ...mapActions([
      "fetchGeneralMethods",
      "fetchPaymentsMethods",
      "fetchConvert",
      "setIndex",
      "setAmount",
      "fetchСreate",
      "setGeneralCode",
      "setMethodCode",
      "setCAT4",
      "setId",
      "setSlug3",
      "setIcon",
      "setName",
      "fetchCategories2",
      "fetchCategories",
      "setSlugPage",
      "fetchAccountsAsync",
    ]),
    ...mapGetters([
      "getGeneralMethods",
      "getPaymentsMethods",
      "getMethodCode",
      "getAccounts",
    ]),
  },
};
</script>
