<template>
  <div>
    <b-card no-body class="cardR">
      <div class="d-flex justify-content-center">
        <h1 class="H1Heading">{{ $t("password.h1") }}</h1>
      </div>
      <div class="d-flex ustify-content-center mt-3">
        <p class="PText ms-auto me-auto">{{ $t("password.p1") }}</p>
      </div>
      <b-row align-h="center" class="mt-3 mt-lg-4">
        <b-col lg="7">
          <b-form @submit.prevent="password">
            <validation-provider
              name="E-mail"
              :rules="{ required: true, min: 3, email, max: 250 }"
              v-slot="validationContext"
            >
              <b-form-group>
                <b-form-input
                  v-model="email"
                  :placeholder="$t('password.pl1')"
                  class="input"
                  :state="getValidationState(validationContext)"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <div class="d-flex mt-3 justify-content-center text-center">
              {{ $t(errorLogin) }}
            </div>
            <b-button class="greenbtn w-100 mt-4" @click="sub" type="submit">{{
              $t("password.btn")
            }}</b-button>
          </b-form>
          <div class="d-flex mt-3 justify-content-center">
            <span class="P2TextB"> {{ $t("password.p2") }}</span>
            <router-link to="/registration" class="P2TextB ms-2 RL"
              >{{ $t("password.p3")
              }}<img src="../assets/img/Arrow1.svg" class="ms-2" alt=""
            /></router-link>
          </div>
          <!-- <div class="d-flex justify-content-center mt-3">
            <div
              @click="swichLangEn"
              class="d-flex justify-content-center align-items-center"
              style="flex-basis: 72px; cursor: pointer"
            >
              <img src="../assets/img/en.svg" alt="" />
              <span class="Yaz ms-2">en</span>
            </div>
            <div
              @click="swichLangRu"
              class="d-flex justify-content-center align-items-center"
              style="flex-basis: 72px; cursor: pointer"
            >
              <img src="../assets/img/ru.svg" alt="" />
              <span class="Yaz ms-2">ru</span>
            </div>
          </div> -->
        </b-col>
      </b-row>
    </b-card>
    <ModalPassword></ModalPassword>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ModalPassword from "../components/modal/modalPassword.vue";
export default {
  data() {
    return {
      errorLogin: "",
    };
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.password"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://lk.easypayments.online/reset-password",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getSubmit"]),
    submit() {
      return this.getSubmit;
    },
  },
  methods: {
    sub() {
      this.setSubmit(true);
    },
    ...mapActions(["setSubmit"]),
    getValidationState({ dirty, validated, valid = null }) {
      if (this.submit) {
        return dirty || validated ? valid : null;
      }
    },
    password() {
      let user = {
        email: this.email,
      };
      this.errorLogin = "";
      this.$store
        .dispatch("password", user)
        .then(() => {
          this.$root.$emit(
            "bv::show::modal",
            "modalPassword",
            "#modalPassword"
          );
        })
        .catch((err) => (this.errorLogin = err.response.data.msg));
    },
    // swichLangEn() {
    //   this.$i18n.locale = "En";
    //   localize("en", en);
    //   localStorage.setItem("locale", "En");
    // },
    // swichLangRu() {
    //   this.$i18n.locale = "ru";
    //   localize("ru", ru);
    //   localStorage.setItem("locale", "ru");
    // },
  },
  components: { ModalPassword },
};
</script>
