<template>
  <div>
    <b-modal id="modal2" class="model2" ref="my-modal2" centered hide-footer hide-header title-sr-only:false>
      <div @click="hideModal" style="width:10px;height:10px;position:absolute;top:3px;right:10px;cursor:pointer">
        <img src="../../assets/img/krest.svg" alt="">
      </div>
      <h2 class="PTextB">{{ $t('modal2.h2') }}</h2>
      <p class="P1Text mt-3">{{ $t('modal2.p') }}</p>
      <b-form>
        <b-form-group class="mt-3">
          <b-form-input class="input" v-model="promocode"></b-form-input>
        </b-form-group>
      </b-form>
      <div class="d-flex justify-content-center mt-3">
{{msg}}
        </div>
      <div class="d-flex justify-content-center mt-3">
        <b-button class="greenbtn" @click="usePromo">{{ $t('modal2.btn') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex"
import { httpClient } from "../../HttpClientV1/HttpClient"

export default {
  data(){
    return{
      promocode: null,
      msg: null,
    }
  },
  methods: {
    ...mapActions(
      [
        'fetchPlans'
      ]
    ),
    hideModal() {
      this.$refs['my-modal2'].hide()
    },
    usePromo(){
      httpClient.get('easypay/api/v4/promocode/use?promocode='+this.promocode)
      .then((resp)=>{
        if(resp.data.status == 'success'){
          localStorage.setItem('promocode', this.promocode)
          window.location.reload()
        }
        else{
          this.msg = 'Промокод не найден'
        }
      })
      .catch(()=>{
         this.msg = 'Промокод не найден'
      })
    }
  }
}
</script>