<template>
  <div style="min-height: 99vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cadrSe1">
          <b-row>
            <b-col md="6">
              <h1 class="H1Heading">{{ $t("settings.h1") }}</h1>
              <p class="PText mt-4">{{ $t("settings.p1") }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col lg="5" xl="5">
        <b-card no-body class="cardSe2">
          <div class="user-data d-block d-md-none">
            <h2 class="PTextB">Данные пользователя</h2>
            <div class="email-block d-flex align-items-center">
              <div class="email-avatar">
                {{ getEmail.slice(0, 1).toUpperCase() }}
              </div>
              <p class="email-text ms-2 mb-0">{{ getEmail }}</p>
            </div>
          </div>
          <h2 class="PTextB mt-3 mt-md-0">{{ $t("settings.h2") }}</h2>
          <b-form @submit.prevent="ChangePasswordMethod">
            <p class="P1Text mt-3">{{ $t("settings.p2") }}</p>
            <validation-provider
              name=" "
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <b-form-group>
                <b-form-input
                  class="input w-100"
                  type="password"
                  v-model="old_password"
                  :state="getValidationState(validationContext)"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <p class="P1Text mt-3">{{ $t("settings.p3") }}</p>
            <validation-provider
              name=" "
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <b-form-group>
                <b-form-input
                  class="input w-100"
                  type="password"
                  v-model="new_password"
                  :state="getValidationState(validationContext)"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <p class="P1Text mt-3">{{ $t("settings.p4") }}</p>
            <validation-provider
              name=" "
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <b-form-group>
                <b-form-input
                  class="input w-100"
                  type="password"
                  v-model="new_password_replay"
                  :state="getValidationState(validationContext)"
                  autocomplete="off"
                ></b-form-input>
                <b-form-invalid-feedback id="input-1-live-feedback">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="d-flex justify-content-center mt-3">
              {{ $t(error_change_pwd) }}
            </div>
            <b-button class="greenbtn w-100 mt-4" @click="sub" type="submit"
              >{{ $t("settings.btn") }}
            </b-button>
          </b-form>
        </b-card>
      </b-col>
      <b-col lg="5" xl="5" class="d-block d-md-none mt-4">
        <b-card no-body class="cardSe2">
          <h2 class="PTextB">Юридическая информация</h2>
          <div class="arrow-links mt-3">
            <a
              href="https://lk.easypayments.online/static/offer/%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0%20EasyPayments.pdf"
              target="_blank"
              class="arrow-link d-flex align-items-center justify-content-between"
            >
              <span>Договор оферты</span>
              <img src="../assets/img/right.png" alt="" />
            </a>
            <a
              href="https://easypayments.online/terms"
              target="_blank"
              class="arrow-link d-flex align-items-center justify-content-between mt-3"
            >
              <span>Пользовательское соглашение</span>
              <img src="../assets/img/right.png" alt="" />
            </a>
            <a
              href="https://easypayments.online/payment-terms"
              target="_blank"
              class="arrow-link d-flex align-items-center justify-content-between mt-3"
            >
              <span>Условия оплаты</span>
              <img src="../assets/img/right.png" alt="" />
            </a>
          </div>
        </b-card>
      </b-col>
      <b-col lg="7" xl="7">
        <b-card no-body class="cardSe2 mt-4 mt-lg-0 MBcard">
          <div class="d-flex justify-content-between">
            <h2 class="PTextB">{{ $t("settings.h22") }}</h2>
            <span
              @click="logout()"
              class="endbtn d-none d-md-block"
              style="cursor: pointer"
              >{{ $t("settings.end") }}
              <img src="../assets/img/end.svg" class="ms-1" alt=""
            /></span>
          </div>
          <div
            v-for="(item, index) in getHistory()"
            :key="index"
            class="d-flex justify-content-between mt-2"
          >
            <span class="P2Text">{{ item.created }}</span>
            <span class="P2Text">{{ item.ip }}</span>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <span @click="logout()" class="endbtn d-block d-md-none"
              >{{ $t("settings.end") }}
              <img src="../assets/img/end.svg" class="ms-1" alt=""
            /></span>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("meta.settings"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/settings",
        },
      ],
    };
  },
  data() {
    return {
      old_password: null,
      new_password: null,
      new_password_replay: null,
      error_change_pwd: null,
    };
  },
  mounted() {
    this.fetchTemplateHistory();
    console.log(this.getEmail);
  },
  methods: {
    sub() {
      this.setSubmit(true);
    },
    getValidationState({ dirty, validated, valid = null }) {
      if (this.submit) {
        return dirty || validated ? valid : null;
      }
    },
    ChangePasswordMethod() {
      let user = {
        old_password: this.old_password,
        new_password: this.new_password,
        new_password_replay: this.new_password_replay,
      };
      this.$store
        .dispatch("ChangePassword", user)
        .then((response) => {
          this.old_password =
            this.new_password =
            this.new_password_replay =
              null;
          this.error_change_pwd = "success_password";
        })
        .catch((err) => {
          console.log("ERROR", err.response.error);
          this.error_change_pwd = err.response.data.error;
        });
    },
    ...mapActions(["fetchTemplateHistory", "setSubmit", "logout"]),
    ...mapGetters(["getHistory"]),
  },
  computed: {
    ...mapGetters(["getSubmit", "getEmail"]),
    submit() {
      return this.getSubmit;
    },
  },
};
</script>

<style lang="css">
.email-avatar {
  width: 40px;
  height: 40px;
  background: #1484d5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}

.email-text {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #3c3c3c;
}

.arrow-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #3c3c3c;
  text-decoration: none;
}

.arrow-link img {
  width: 8px;
}
</style>
