<template>
  <div>
    <transition name="cardA2" mode="in-out">
      <div v-if="step == 1">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="cena-h3">
            Анкета для регистрации компании LLP в Великобритании
          </h3>
          <span class="P1Text">{{ step }}/2</span>
        </div>
        <p class="P1Text mt-3">
          Пожалуйста, ответьте на вопросы в отношении LLP (товарищество с
          ограниченной ответственностью), которое Вы планируете
          зарегистрировать.
        </p>

        <b-form>
          <h3 class="PText2 mt-4">1. Название и деятельность</h3>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                1.1. Пожалуйста, дайте 3 возможных названия компании, начиная с
                предпочтительного варианта. Используйте LLP в конце
                названия.<span style="color: red">*</span>
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-textarea
                  class="input"
                  style="height: 100px"
                  v-model="names"
                  no-resize
                  placeholder="MyCompany LLP"
                >
                </b-form-textarea>
              </b-form-group>
              <p v-else class="P2Text" v-html="names"></p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                1.2. Как можно подробнее опишите, чем компания собирается
                заниматься.<span style="color: red">*</span>
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-textarea
                  class="input"
                  style="height: 100px"
                  v-model="description"
                  no-resize
                  placeholder="Интернет-магазин в нише …"
                >
                </b-form-textarea>
              </b-form-group>
              <p v-else class="P2Text" v-html="description"></p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                1.3. Используя данный классификатор
                <a
                  target="_blank"
                  class="RL"
                  href="https://resources.companieshouse.gov.uk/sic/"
                >
                  https://resources.companieshouse.gov.uk/sic,</a
                >
                выберите коды деятельности компании (не более 4, через
                запятую).<span style="color: red">*</span>
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-input
                  class="input"
                  v-model="codes"
                  type="text"
                  placeholder="01110, 01120"
                >
                </b-form-input>
              </b-form-group>
              <p v-else class="P2Text" v-html="codes"></p>
            </b-col>
          </b-row>
          <h3 class="PText2 mt-4">2. Партнеры</h3>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                2.1 Укажите полные имена партнеров — физических лиц или компаний
                (не менее 2-х). Нам нужно идентифицировать каждого партнера
                (список необходимых документов будет запрошен на следующем этапе
                заполнения формы).<span style="color: red">*</span>
              </p>
            </b-col>
            <b-col lg="6" :key="keyPartner">
              <b-row
                v-for="(item, index) in getPartners"
                :key="index"
                class="mb-2"
              >
                <b-col sm="7">
                  <b-form-group v-if="isSave">
                    <b-form-input
                      class="input"
                      readonly
                      :value="getInfoPartner(index)"
                      placeholder="Иванов Иван Иванович"
                      @click="openEditInfoPerson(index)"
                    >
                    </b-form-input>
                  </b-form-group>
                  <p v-else class="P2Text" @click="openEditInfoPerson(index)">
                    {{ getInfoPartner(index) }}
                  </p>
                </b-col>
                <b-col sm="5" class="d-flex align-items-center mt-2 mt-sm-0">
                  <div class="d-flex" v-if="isSave">
                    <b-form-group>
                      <b-form-checkbox
                        :id="'checkbox-' + index"
                        v-model="item.individual"
                        :name="'checkbox-' + index"
                        class="Stext2"
                      >
                        Физическое лицо
                      </b-form-checkbox>
                    </b-form-group>
                    <img
                      v-if="index > 1"
                      src="@/assets/img/krest.svg"
                      class="ms-4"
                      @click="udab(index)"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <p v-if="item.individual" class="P2Text">Физическое лицо</p>
                    <p v-else class="P2Text">Юридическое лицо</p>
                  </div>
                </b-col>
              </b-row>
              <b-button v-if="isSave" class="Combtn mt-2" @click="dobab()"
                ><img src="@/assets/img/com.svg" class="me-3" alt="" />Добавить
                партнера
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                2.2. Если за последние 20 лет вы или ваши партнеры использовали
                другое имя и/или фамилию в бизнес целях, просьба указать здесь.
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-input
                  class="input"
                  v-model="other_second_name"
                  placeholder="Имя / Фамилия / Отчество"
                >
                </b-form-input>
              </b-form-group>
              <p v-else class="P2Text" v-html="other_second_name"></p>
            </b-col>
          </b-row>
          <h3 class="PText2 mt-4">3. Партнеры</h3>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                3.1. Если в партнерстве более 2 партнеров, укажите как минимум
                двух ответственных партнеров.<span style="color: red">*</span>
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-textarea
                  class="input"
                  v-model="responsible_partners"
                  style="height: 100px"
                  no-resize
                >
                </b-form-textarea>
              </b-form-group>
              <p v-else class="P2Text" v-html="responsible_partners"></p>
            </b-col>
          </b-row>
          <h3 class="PText2 mt-4">4. Доли партнеров</h3>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                4.1. Укажите доли в LLP, принадлежащие каждому из партнеров в %
                и денежном эквиваленте (сумма – 100%).<span style="color: red"
                  >*</span
                >
              </p>
            </b-col>
            <b-col lg="6" :key="keyPartner2">
              <div
                class="d-flex align-items-center mb-2"
                v-for="(item, index) in getPartners"
                :key="index"
              >
                <b-form-group class="me-3" v-if="isSave">
                  <b-form-input
                    class="input"
                    type="number"
                    v-model="item.share"
                    @change="handleChange(index)"
                    style="width: 70px"
                    max="100"
                    placeholder="100%"
                  >
                  </b-form-input>
                </b-form-group>
                <span
                  v-else
                  class="P2Text"
                  style="margin-top: 1px"
                  v-html="item.share"
                ></span
                ><span v-if="!isSave" class="P2Text" style="margin-top: 1px"
                  >%</span
                >
                <span v-html="getInfoPartner(index)" class="Stext2 ms-3"></span>
              </div>
            </b-col>
          </b-row>
          <h3 class="PText2 mt-4">
            5. Лица со значительным контролем, бенефициары
          </h3>
          <b-row class="mt-3">
            <b-col lg="6">
              <p class="P2Text">
                5.1. Укажите все лица, которые прямо или косвенно имеют
                значительный контроль над компанией.<span style="color: red"
                  >*</span
                >
              </p>
            </b-col>
            <b-col lg="6">
              <b-form-group v-if="isSave">
                <b-form-textarea
                  class="input"
                  v-model="persons_control"
                  style="height: 100px"
                  no-resize
                  placeholder="Иванов Иван Иванович"
                >
                </b-form-textarea>
              </b-form-group>
              <p v-else class="P2Text" v-html="persons_control"></p>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="6" offset-lg="6">
              <div v-if="error_share != ''" class="P2Text mb-3">
                {{ error_share }}<span style="color: red">*</span>
              </div>

              <div v-if="errorPOL" class="P2Text mb-3">
                Заполните все поля <span style="color: red">*</span>
              </div>
              <div
                class="d-flex flex-column flex-sm-row justify-content-left align-items-center"
              >
                <b-button
                  v-if="isSave"
                  class="greenbtn1 me-sm-3 widthbtn1"
                  @click="savedForm(item.id)"
                >
                  Сохранить</b-button
                >
                <b-button
                  v-else
                  class="greenbtn1 me-sm-3 widthbtn1"
                  @click="isSave = !isSave"
                >
                  Редактировать</b-button
                >
                <b-button
                  class="greenbtn mt-2 mt-sm-0 widthbtn1"
                  @click="
                    stV(
                      names,
                      description,
                      codes,
                      other_second_name,
                      persons_control,
                      responsible_partners,
                      item.id
                    )
                  "
                >
                  Продолжить</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </transition>
    <transition name="cardA2" mode="in-out">
      <div v-if="step == 2">
        <div class="d-flex justify-content-between align-items-center">
          <h3 class="cena-h3">Требования для идентификации</h3>
          <div class="d-flex">
            <img
              src="@/assets/img/cardA21.svg"
              style="cursor: pointer"
              class="me-2"
              @click="step = 1"
              alt=""
            />
            <span class="P1Text">{{ step }}/2</span>
          </div>
        </div>
        <p class="P1Text mt-3">
          Пожалуйста, предоставьте следующие документы для каждого партнера и
          лица со значительным контролем/бенефициара в зависимости от того,
          являются ли они физическими / юридическими лицами.
        </p>
        <p class="P1Text mt-3">
          Если какой-либо из документов не на английском или русском языке,
          пожалуйста, представьте заверенные переводы.
        </p>
        <p class="P1Text mt-3">
          <span class="Green" style="font-family: 'ProximaNova-Regular'"
            >Важно!</span
          >
          В процессе регистрации могут потребоваться дополнительные документы.
        </p>
        <div v-for="(item, index_p2) in getPartners2" :key="index_p2">
          <h3 class="PText2 mt-4">{{ getInfoPartner2(index_p2) }}</h3>
          <div v-for="(item_doc, index1) in item.documents" :key="index1">
            <b-row class="mt-4">
              <b-col lg="6">
                <p class="P2Text mb-0">{{ item_doc.name }}</p>
                <p
                  class="text-muted small mt-0"
                  v-if="item_doc.description != 'None'"
                >
                  {{ item_doc.description }}
                </p>
              </b-col>
              <b-col lg="6">
                <div class="d-flex" v-if="item_doc.count == 1">
                  <label
                    v-if="statusDoc == null"
                    draggable="true"
                    :for="'file' + item_doc.name + 0 + item.id"
                    @dragenter="handleDragEnter($event)"
                    @dragover="handleDragOver($event)"
                    @drop="handleDrop($event)"
                    @dragstart="handleDragStart($event)"
                    @dragleave="handleDragLeave($event)"
                    @dragend="handleDragEnd($event)"
                    :class="[item_doc.active ? 'file1' : 'file']"
                  ></label>
                  <label
                    v-if="statusDoc == 'success'"
                    draggable="true"
                    :for="'file' + item_doc.name + 0 + item.id"
                    @dragenter="handleDragEnter($event)"
                    @dragover="handleDragOver($event)"
                    @drop="handleDrop($event)"
                    @dragstart="handleDragStart($event)"
                    @dragleave="handleDragLeave($event)"
                    @dragend="handleDragEnd($event)"
                    class="file1"
                  ></label>
                  <input
                    type="file"
                    class="file"
                    draggable="true"
                    @drop="handleDrop($event)"
                    @dragstart="handleDragStart($event)"
                    @dragover="handleDragOver($event)"
                    @dragleave="handleDragLeave($event)"
                    @dragend="handleDragEnd($event)"
                    :id="'file' + item_doc.name + 0 + item.id"
                    :doc_id="item_doc.id"
                    :doc_name="item_doc.name"
                    :partner_id="item.id"
                    :code="0"
                    @change="handleFileUpload($event)"
                  />
                </div>
                <div class="d-flex" v-if="item_doc.count > 1">
                  <div
                    v-for="(item_doc_count, index2) in item_doc.count"
                    :key="index2"
                  >
                    <label
                      @click="codeI(index2)"
                      draggable="true"
                      @drop="handleDrop($event)"
                      @dragstart="handleDragStart($event)"
                      @dragover="handleDragOver($event)"
                      @dragenter="handleDragEnter($event)"
                      @dragleave="handleDragLeave($event)"
                      @dragend="handleDragEnd($event)"
                      v-if="statusDoc == null"
                      :for="'file' + item_doc.name + item_doc_count + item.id"
                      class="mr-3"
                      :class="[item_doc.active[index2] ? 'file1' : 'file']"
                    ></label>
                    <label
                      @click="codeI(index2)"
                      draggable="true"
                      @drop="handleDrop($event)"
                      @dragstart="handleDragStart($event)"
                      @dragenter="handleDragEnter($event)"
                      @dragover="handleDragOver($event)"
                      @dragleave="handleDragLeave($event)"
                      @dragend="handleDragEnd($event)"
                      v-if="statusDoc == 'success'"
                      :for="'file' + item_doc.name + item_doc_count + item.id"
                      class="file1 mr-3"
                    ></label>
                    <input
                      type="file"
                      @drop="handleDrop($event)"
                      draggable="true"
                      @dragstart="handleDragStart($event)"
                      class="file"
                      :id="'file' + item_doc.name + item_doc_count + item.id"
                      :doc_id="item_doc.id"
                      :doc_name="item_doc.name"
                      :partner_id="item.id"
                      :code="item_doc_count"
                      @change="handleFileUpload($event)"
                    />
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-row class="mt-3">
          <b-col lg="6" offset-lg="6">
            <div v-if="errorPOL" class="P2Text mb-3">
              Загрузите все файлы<span style="color: red">*</span>
            </div>
            <div
              class="d-flex flex-column flex-sm-row justify-content-left align-items-center"
            >
              <b-button class="greenbtn1 me-sm-3 widthbtn1" @click="step = 1"
                >Назад</b-button
              >
              <b-button class="greenbtn mt-2 mt-sm-0 widthbtn1" @click="Plan1()"
                >Отправить информацию</b-button
              >
            </div>
          </b-col>
        </b-row>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    item: {
      type: Object,
    },
  },
  data: () => ({
    step: 1,
    isSave: true,
    error_share: "",
    errorPOL: false,
    keyPartner2: 0,
  }),
  methods: {
    ...mapActions([
      "setPlanId",
      "sendDocuments",
      "setPartnerIndex",
      "setNames",
      "setDescription",
      "setCodes",
      "setPersonsControl",
      "setOtherSecondName",
      "setPartners",
      "setKeyPartner",
      "setSelectedPartner",
      "fetchSendForm",
      "setResponsiblePartners",
      "addNewElementPartners",
      "udalElementPartners",
      "fetchForms",
      "uploadFile",
      "setCode",
      "fetchDocuments",
      "fetchDocuments2",
      "setCAT3",
      "setIcon",
      "setSlug3",
      "setSlugPage",
      "setId",
      "setCAT4",
      "fetchCategories2",
      "setFormId",
    ]),
    handleDragOver(e) {
      // Предотвратить действие по умолчанию
      e.preventDefault();
      // Установите допустимый тип перетаскиваемого элемента
      e.dataTransfer.dropEffect = "copy";
    },
    handleDragEnter(e) {
      // Добавить класс при наведении
      e.target.classList.add("drag-enter");
    },
    handleDragStart(e) {
      // Установите тип перетаскиваемого элемента
      console.log(e);
      e.dataTransfer.setData("text/plain", e.target.id);
    },
    handleDragLeave(e) {
      // Удалить класс при выходе
      e.target.classList.remove("drag-enter");
    },
    handleDragEnd(e) {
      // Удалить класс при окончании перетаскивания
      e.target.classList.remove("drag-enter");
    },
    handleDrop(event) {
      event.target.classList.add("loading");
      console.log(event);
      // Предотвратить действие по умолчанию
      event.preventDefault();
      // Получите тип перетаскиваемого элемента
      const docId = event.dataTransfer.getData("text/plain");
      // Получите данные о файле и выполните его загрузку
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        var data = {
          file: files[0],
          name: files[0].name,
          partner_id: event.target.control.getAttribute("partner_id"),
          document_id: event.target.control.getAttribute("doc_id"),
          code:
            event.target.control.getAttribute("doc_name") +
            "_" +
            event.target.control.getAttribute("code"),
        };
        this.uploadFile(data)
          .then((response) => {
            const previousSibling =
              event.target.control.previousSibling.previousSibling;
            if (
              previousSibling &&
              previousSibling.nodeType === Node.ELEMENT_NODE
            ) {
              previousSibling.classList.add("file1");
            }
            event.target.disabled = true;
          })
          .catch((error) => {
            console.error("Ошибка загрузки файла", error);
            // Обрабатываем ошибку загрузки файла
          });

        event.target.classList.remove("drag-enter");
        setTimeout(() => {
          event.target.classList.remove("loading");
        }, 1000);
      }
    },
    handleFileUpload(event) {
      const previousSibling = event.target.previousSibling.previousSibling;
      previousSibling.classList.add("loading");
      console.log("DOC_ID", event.target.doc_id);
      var file = event.target.files[0]; //Получение бинарного файла
      var data = {
        file: file,
        name: file.name,
        partner_id: event.target.getAttribute("partner_id"),
        document_id: event.target.getAttribute("doc_id"),
        code:
          event.target.getAttribute("doc_name") +
          "_" +
          event.target.getAttribute("code"),
      };
      this.uploadFile(data)
        .then((response) => {
          const previousSibling = event.target.previousSibling.previousSibling;
          if (
            previousSibling &&
            previousSibling.nodeType === Node.ELEMENT_NODE
          ) {
            previousSibling.classList.add("file1");
          }
          event.target.disabled = true;
        })
        .catch((error) => {
          console.error("Ошибка загрузки файла", error);
          // Обрабатываем ошибку загрузки файла
        });
      setTimeout(() => {
        previousSibling.classList.remove("loading");
      }, 1000);
    },
    Plan1(form_id) {
      if (form_id != undefined) {
        this.setFormId(form_id);
      }
      if (this.getUploaded_document.length >= this.getColM) {
        document.getElementById("inst").scrollIntoView();
        this.sendDocuments();
        this.fetchForms();
        this.fetchDocuments();
        this.plan1 = false;
        this.str = false;
      } else {
        this.errorPOL = true;
      }
    },
    stV(
      names = "",
      description = "",
      codes = "",
      other_second_name = "",
      persons_control = "",
      responsible_partners = "",
      paid_plan_id = ""
    ) {
      var all_share = 0;
      this.error_share = "";
      this.errorPOL = false;
      for (var i = 0; i < this.getPartners.length; i++) {
        if (this.getPartners[i].share != NaN) {
          all_share += parseFloat(this.getPartners[i].share);
        }
      }
      if (all_share != 100) {
        this.error_share = "Сумма всех долей должна быть 100%";
        return;
      }
      if (
        names != "" &&
        description != "" &&
        codes != "" &&
        persons_control != "" &&
        responsible_partners != ""
      ) {
        document.getElementById("inst").scrollIntoView();
        this.fetchSendForm(paid_plan_id);
        this.errorPOL = false;
        this.step = 2;
        this.isSave = false;
      } else {
        this.errorPOL = true;
      }
      localStorage.setItem("names", names);
      localStorage.setItem("description", description);
      localStorage.setItem("codes", codes);
      localStorage.setItem("other_second_name", other_second_name);
      localStorage.setItem("persons_control", persons_control);
      localStorage.setItem("responsible_partners", responsible_partners);
      localStorage.setItem("partners", partners);
    },
    savedForm(paid_plan_id) {
      this.isSave = false;
      this.fetchSendForm(paid_plan_id);

      this.fetchForms();
    },
    getInfoPartner2(index) {
      try {
        let second =
          this.getPartners2[index].data.secondname != undefined
            ? this.getPartners2[index].data.secondname
            : "";
        return (
          this.getPartners2[index].data.firstname +
          " " +
          second +
          " " +
          this.getPartners2[index].data.surename
        );
      } catch {
        return "";
      }
    },
    codeI(index2) {
      this.setCode(index2);
    },
    dobab() {
      this.addNewElementPartners();
    },
    udab(index) {
      this.udalElementPartners(index);
    },
    openEditInfoPerson(index) {
      this.setPartnerIndex(index);
      this.setKeyPartner();
      this.setSelectedPartner();
      let self = this;
      setTimeout(() => {
        self.$root.$emit("bv::show::modal", "modal-personal-data");
        // self.$bvModal.show("modal-personal-data");
      }, 100);
    },
    getInfoPartner(index) {
      try {
        let second =
          this.getPartners[index].data.secondname != undefined
            ? this.getPartners[index].data.secondname
            : "";
        return (
          this.getPartners[index].data.firstname +
          " " +
          second +
          " " +
          this.getPartners[index].data.surename
        );
      } catch {
        return "";
      }
    },

    handleChange(index) {
      var Partners = this.getPartners;
      if (Partners.length > 1) {
        console.log("partners > 1");
        if (this.isOverLimit) {
          const totalExceptCurrentField =
            this.total - parseFloat(Partners[index].share);
          const diffPerField = this.diff / (Partners.length - 1);

          Partners.forEach((field, idx) => {
            if (idx !== index) {
              field.share -= diffPerField;
              if (parseFloat(field.share) < 0) {
                field.share = 0;
              }
            }
          });
        }
      } else {
        Partners[0].share = 100;
      }
      this.setPartners(Partners);
      this.keyPartner2 += 1;
      // var Partners = this.getPartners;

      // console.log("Partners", Partners);
      // if (this.isOverLimit) {
      //   Partners[index].share -= this.diff;
      // } else {
      //   Partners[index].share = 100;
      // }

      // this.setPartners(Partners);
    },
  },
  computed: {
    ...mapGetters([
      "getPartners",
      "getPartners2",
      "getForms",
      "getColM",
      "getUploaded_document",
      "getPlanId",
      "getNames",
      "getDescription",
      "getCodes",
      "getPersonsControl",
      "getOtherSecondName",
      "getResponsiblePartners",
      "getStatusDoc",
      "getKeyPartner",
    ]),
    total() {
      let sum = 0;
      this.getPartners.forEach((field) => {
        sum += Number(field.share);
      });
      return sum;
    },

    isOverLimit() {
      return this.total > 100;
    },
    diff() {
      return this.isOverLimit ? this.total - 100 : 0;
    },
    statusDoc: {
      get() {
        return this.getStatusDoc;
      },
    },
    names: {
      get() {
        return this.getNames;
      },
      set(names) {
        this.setNames(names);
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(description) {
        this.setDescription(description);
      },
    },
    keyPartner() {
      return this.getKeyPartner;
    },
    codes: {
      get() {
        return this.getCodes;
      },
      set(codes) {
        this.setCodes(codes);
      },
    },
    other_second_name: {
      get() {
        return this.getOtherSecondName;
      },
      set(other_second_name) {
        this.setOtherSecondName(other_second_name);
      },
    },
    responsible_partners: {
      get() {
        return this.getResponsiblePartners;
      },
      set(responsible_partners) {
        this.setResponsiblePartners(responsible_partners);
      },
    },
    persons_control: {
      get() {
        return this.getPersonsControl;
      },
      set(persons_control) {
        this.setPersonsControl(persons_control);
      },
    },
  },
};
</script>

<style>
label.file.drag-enter:before {
  background-image: url(../../assets/img/upload.svg) !important;
}
label.file1.drag-enter:before {
  background-image: url(../../assets/img/upload.svg) !important;
}
label.file.loading:before,
label.file.loadinge.drag-enter:before,
label.file1.loading:before,
label.file1.loadinge.drag-enter:before {
  background-image: url(../../assets/img/giphy.gif) !important;
  background-size: 35% !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
</style>
