import { httpClient } from "../../HttpClientV1/HttpClient";
import router from '../../router/index'
import axios from "axios";
export default {
    state:{
        referral_link:null,
        registrations:null,
        payments:null,
        payout:null,
    },
    mutations: {
        updateReferralLink(state, data){
            state.referral_link = data
        },
        updateRegistrations(state, data){
            state.registrations = data
        },
        updatePayments(state, data){
            state.payments = data
        },
        updatePayout(state, data){
            state.payout = data
        },
      },
      actions: {
        fetchInfo(ctx) {
            httpClient.post("easypay/api/v4/referral/info").then((response) => {
              ctx.commit("updateReferralLink", response.data.referral_link);
              ctx.commit("updateRegistrations", response.data.registrations);
              ctx.commit("updatePayments", response.data.payments);
              ctx.commit("updatePayout", response.data.payout);
            });
          },
      },
      getters: {
        getReferralLink: (state) => state.referral_link,
        getRegistrations: (state) => state.registrations,
        getPayments: (state) => state.payments,
        getPayout: (state) => state.payout,
      }
}