<template>
  <div>
    <b-modal
      id="modal1"
      class="model1"
      ref="my-modal1"
      centered
      hide-footer
      hide-header
      title-sr-only:false
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="PTextB">{{ $t("modal1.h2") }}</h2>
        <div class="BalBar1">$2</div>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <p class="P1Text">{{ $t("modal1.p") }} $2</p>
      </div>
      <div class="d-flex justify-content-center mt-2">
        {{ msg }}
      </div>
      <div class="d-flex justify-content-center mt-2">
        <b-button class="greenbtn" @click="bay_proxy">{{
          $t("modal1.btn")
        }}</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      msg: null,
    };
  },
  methods: {
    ...mapActions(["buyProxy", "fetchAccounts"]),
    bay_proxy() {
      this.buyProxy()
        .then((response) => {
          if (response.data.code == 200) {
            this.hideModal();
            this.fetchAccounts();
          }
        })
        .catch((error) => {
          this.msg = this.$i18n.t(error.response.data.msg);
        });
    },
    hideModal() {
      this.$refs["my-modal1"].hide();
    },
  },
};
</script>
