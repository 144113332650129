import { httpClient } from "../../HttpClientV1/HttpClient";
import router from '../../router/index'
import axios from "axios";
export default {
    state:{
      categories:[],
      categories2:[],
      bady:null,
      slug:null,
      icon:null,
      name:null,
      id:null
    },
    mutations: {
      updateCAT(state, data){
            state.name = data
        },
        updateBady(state, data){
            state.bady = data
        },
        updateId(state, data){
            state.id = data
        },
        updateCategories(state, data){
            state.categories = data
        },
        updateCategories2(state, data){
            state.categories2 = data
        },
        updateSlug(state, data){
            state.slug = data
        },
        updateIcon(state, data){
            state.icon = data
        },
        updateName(state, name){
            state.name = state.categories[name].name
        },

      },
      actions: {
        setId(ctx, data){
            ctx.commit('updateId', data)
            ctx.dispatch('fetchBady')
        },
        setIdIn(ctx, data){
            ctx.commit('updateId', data)
            ctx.dispatch('fetchCategories22')
            ctx.dispatch('fetchBady')
        },
        
        setSlug3(ctx, data){
          
          ctx.commit('updateId', data.id)
          ctx.commit('updateSlug', data.slug)
          ctx.dispatch('fetchCategories22')
      },
        setSlug(ctx, data){
            ctx.commit('updateSlug', data)
            ctx.dispatch('setSlug2')
        },
        setSlugPage(ctx, data){
            ctx.commit('updateSlug', data)
        },
        setSlug2(ctx){
            router.push('/support/' + ctx.state.slug + '/#int')
        },
        setIcon(ctx, data){
            ctx.commit('updateIcon', data)
        },
        setName(ctx, data){
            ctx.commit('updateName', data)
        },
        setCAT4(ctx,value) {
          httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
            var data = response.data.find(item => item.slug === value)
            console.log('DATA', response.data);
            ctx.commit("updateCAT", data.name);
            ctx.commit("updateIcon", data.icon);
          });
        },
        setCAT3(ctx) {
          httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
            ctx.commit("updateCAT", response.data[6].name);
          });
        },
        setCAT2(ctx) {
          httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
            ctx.commit("updateCAT", response.data[4].name);
          });
        },
        setCAT1(ctx) {
          httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
            ctx.commit("updateCAT", response.data[0].name);
          });
        },
        setCAT(ctx) {
          httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
            var data = response.data.find(item => item.slug === 'withdrawal')
            ctx.commit("updateCAT", data.name);
          });
        },
        fetchCategories(ctx) {
            httpClient.get('easypay/api/v4/helpcenter/general?locale=' + localStorage.getItem("locale").toLowerCase()).then((response) => {
              ctx.commit("updateCategories", response.data);
              ctx.commit("updateName", 0);
            });
          },
          fetchCategories2(ctx) {
            httpClient.get('easypay/api/v4/helpcenter/categorys?locale=' + localStorage.getItem("locale").toLowerCase() + '&slug=' + ctx.state.slug).then((response) => {
              ctx.commit("updateCategories2", response.data);
              ctx.commit("updateId", response.data[0].id);
              ctx.dispatch('fetchBady')
            });
          },
          fetchCategories22(ctx) {
            httpClient.get('easypay/api/v4/helpcenter/categorys?locale=' + localStorage.getItem("locale").toLowerCase() + '&slug=' + ctx.state.slug).then((response) => {
              ctx.commit("updateCategories2", response.data);
              ctx.dispatch('fetchBady')
            });
          },
          fetchBady(ctx) {
            httpClient.get('easypay/api/v4/helpcenter/bady_category?locale=' + localStorage.getItem("locale").toLowerCase() + '&slug=' + ctx.state.slug + '&id=' + ctx.state.id).then((response) => {
              ctx.commit("updateBady", response.data.body);
            });
          },
      },
      getters: {
        getBady: (state) => state.bady,
        getId: (state) => state.id,
        getIcon: (state) => state.icon,
        getName: (state) => state.name,
        getCategories: (state) => state.categories,
        getCategories2: (state) => state.categories2,
      }
}