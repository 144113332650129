<template>
    <div>
        <b-card no-body class="cardR">
            <div class="d-flex ">
                <h1 class="H1Heading ms-auto me-auto" v-if="success">{{ $t('authorization.h11') }}</h1>
                <h1 class="H1Heading ms-auto me-auto" v-else>{{ $t('email.activation_error') }}</h1>
            </div>
            <div class="d-flex mt-3">
                <p class="PText ms-auto me-auto">{{ msg }}</p>
            </div>
            <b-row class=" mt-3 mt-sm-5">
                <div class="d-flex justify-content-center">
                     <router-link to="/" v-if="success" >
                        <b-button class="greenbtn">{{$t('email.proceed_to_home')}}</b-button>
                    </router-link>
                    <router-link to="/user/account/email/confirm?action=resend" v-else>
                        <b-button class="greenbtn">{{$t('email.resend')}}</b-button>
                    </router-link>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                    <img src="../assets/img/AA.svg" style="width:200px" alt="">
                    </div>

                    <!-- Добавить действие отправки письма -->
            </b-row>
             <div class="d-flex justify-content-center mt-5">
                <div @click="swichLangEn" class="d-flex justify-content-center align-items-center"
                    style="flex-basis: 72px;cursor:pointer">
                    <img src="../assets/img/en.svg" alt=""> <span class="Yaz ms-2">en</span>
                </div>
                <div @click="swichLangRu" class="d-flex justify-content-center align-items-center"
                    style="flex-basis: 72px;cursor:pointer">
                    <img src="../assets/img/ru.svg" alt=""> <span class="Yaz ms-2">ru</span>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions} from 'vuex'
import { httpClient } from "../HttpClientV1/HttpClient"
import {
    localize,
} from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";

export default {
     metaInfo() {
    return {
      title: this.$i18n.t('meta.activeA'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online/user/account/activate/:key' }
      ]
    }
  },
    data() {
        return {
            msg: null,
            success: true
        }
    },
    methods: {
          ...mapActions(
      [
        'setGaid'
      ]
    ),
        swichLangEn() {
            this.$i18n.locale = 'En'
            localize('en', en)
            localStorage.setItem("locale", 'En')
        },

        swichLangRu() {
            this.$i18n.locale = 'ru'
            localize('ru', ru)
            localStorage.setItem("locale", 'ru')
        }
    },
    mounted() {
        httpClient.get('easypay/api/v4/users/activate?key=' + this.$route.params.key + '&locale=' + localStorage.getItem('locale').toLocaleLowerCase())
            .then((response) => {
                if (response.data.status == 'ok') {
                    this.msg = response.data.msg
                    this.success = true
                    this.setGaid(true)
                }
                else {
                    this.msg = response.data.msg
                    this.success = false
                }
            })
    }
}
</script>