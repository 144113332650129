<template>
  <div>
    <b-card no-body class="cardR1 mb-2">
      <h1 class="H1Heading">{{ $t("registration.h1") }}</h1>
      <b-row class="mt-3 mt-md-4">
        <b-col lg="5" md="4" class="pe-md-0">
          <div class="d-flex align-items-center">
            <img src="../assets/img/reg1.svg" style="width: 20px" alt="" />
            <span class="P1Text ms-2"> {{ $t("registration.s1") }}</span>
          </div>
          <div class="d-flex mt-md-4 mt-3 align-items-center">
            <img src="../assets/img/reg2.svg" style="width: 20px" alt="" />
            <span class="P1Text ms-2"> {{ $t("registration.s2") }}</span>
          </div>
          <div class="d-flex mt-md-4 mt-3 align-items-center">
            <img src="../assets/img/reg3.svg" style="width: 20px" alt="" />
            <span class="P1Text ms-2">{{ $t("registration.s3") }}</span>
          </div>
        </b-col>
        <b-col lg="7" md="8" class="p-md-0 mt-4 mt-md-0">
          <b-row>
            <b-col lg="8">
              <div class="d-flex justify-content-between mb-2">
                <p class="P1TextB mt-2 mt-0">{{ $t("registration.p1") }}</p>
                <span class="P1TextB mt-2 mt-0">{{ st }}/2</span>
              </div>
              <div
                v-if="st == 2"
                class="d-flex"
                @click="st = 1"
                style="z-index: 100; cursor: pointer"
              >
                <div class="ms-auto" style="position: relative">
                  <img
                    src="../assets/img/cardA21.svg"
                    style="
                      position: absolute;
                      top: -10px;
                      left: -20px;
                      z-index: 100;
                    "
                    alt=""
                  />
                </div>
              </div>
              <validation-observer
                ref="observer"
                v-slot="{ handleSubmit }"
                v-if="st == 1"
              >
                <b-form @submit.prevent="handleSubmit(sub())">
                  <div>
                    <validation-provider
                      name="E-mail"
                      :rules="{ required: true, min: 3, email, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input
                          v-model="email"
                          :placeholder="$t('registration.pl1')"
                          class="input mt-2"
                          :state="getValidationState(validationContext)"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      name="telegram"
                      :rules="{ required: true, min: 3, max: 250 }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input
                          :placeholder="$t('registration.pl2')"
                          v-model="telegram"
                          class="input"
                          style="margin-top: 25px"
                          :state="getValidationState(validationContext)"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <div class="d-flex flex-column flex-sm-row mt-4">
                      <b-button class="greenbtn widthbtn1" type="submit"
                        >{{ $t("registration.btn1") }}
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>

              <validation-observer
                ref="observer2"
                v-slot="{ handleSubmit }"
                v-if="st == 2"
              >
                <b-form @submit.prevent="handleSubmit(sub2())">
                  <div>
                    <validation-provider
                      :name="$t('registration.pl3')"
                      :rules="{ required: true, min: 8 }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input
                          class="input mt-2"
                          :placeholder="$t('registration.pl3')"
                          type="password"
                          v-model="password1"
                          :state="getValidationState2(validationContext)"
                          autocomplete="off"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      :name="$t('registration.pl4')"
                      :rules="{ required: true, min: 8 }"
                      v-slot="validationContext"
                    >
                      <b-form-group>
                        <b-form-input
                          class="input"
                          style="margin-top: 25px"
                          :placeholder="$t('registration.pl4')"
                          type="password"
                          v-model="password2"
                          :state="getValidationState2(validationContext)"
                          autocomplete="off"
                        ></b-form-input>
                        <b-form-invalid-feedback id="input-1-live-feedback">
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>

                    <div class="d-flex mt-3 justify-content-center">
                      {{ $t(errorLogin) }}
                    </div>
                    <div class="d-flex flex-column flex-sm-row mt-4">
                      <b-button class="greenbtn widthbtn1" type="submit">{{
                        $t("registration.btn2")
                      }}</b-button>
                    </div>
                  </div>
                </b-form>
              </validation-observer>
            </b-col>
          </b-row>

          <div class="d-flex mt-3">
            <p class="P2TextB">{{ $t("registration.p2") }}</p>
            <router-link to="/authorization" class="P2TextB ms-3 RL"
              >{{ $t("registration.p3")
              }}<img src="../assets/img/Arrow1.svg" class="ms-2" alt=""
            /></router-link>
          </div>
          <p class="P2TextB mb-0">{{ $t("registration.p4") }}</p>
          <!-- <div class="d-none justify-content-start mt-3 d-md-flex">
            <div
              @click="swichLangEn"
              class="d-flex justify-content-center align-items-center"
              style="cursor: pointer"
            >
              <img src="../assets/img/en.svg" alt="" />
              <span class="Yaz ms-2">en</span>
            </div>
            <div
              @click="swichLangRu"
              class="d-flex justify-content-center align-items-center"
              style="flex-basis: 72px; cursor: pointer"
            >
              <img src="../assets/img/ru.svg" alt="" />
              <span class="Yaz ms-2">ru</span>
            </div>
          </div> -->
        </b-col>
      </b-row>
      <!-- <div class="d-flex justify-content-center mt-3 d-md-none">
        <div
          @click="swichLangEn"
          class="d-flex justify-content-center align-items-center"
          style="flex-basis: 72px; cursor: pointer"
        >
          <img src="../assets/img/en.svg" alt="" />
          <span class="Yaz ms-2">en</span>
        </div>
        <div
          @click="swichLangRu"
          class="d-flex justify-content-center align-items-center"
          style="flex-basis: 72px; cursor: pointer"
        >
          <img src="../assets/img/ru.svg" alt="" />
          <span class="Yaz ms-2">ru</span>
        </div>
      </div> -->
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { localize } from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("meta.registration"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/registration",
        },
      ],
    };
  },
  data() {
    return {
      email: null,
      password1: null,
      password2: null,
      telegram: null,
      errorLogin: "",
      st: 1,
    };
  },
  computed: {
    ...mapGetters(["getSubmit", "getSubmit2"]),
    submit() {
      return this.getSubmit;
    },

    submit2() {
      return this.getSubmit2;
    },
  },
  methods: {
    async sub() {
      this.setSubmit(true);
      var valids = await this.checkObserver();
      if (typeof valids == "boolean") {
        if (valids == true) {
          this.st = 2;
          this.setSubmit(false);
        }
      }
    },
    checkObserver() {
      return this.$refs.observer.validate();
    },
    checkObserver2() {
      return this.$refs.observer2.validate();
    },
    async sub2() {
      this.setSubmit2(true);
      var valids = await this.checkObserver2();
      if (typeof valids == "boolean") {
        if (valids == true) {
          this.errorLogin = "";
          this.registration();
        }
      }
    },
    ...mapActions(["setSubmit", "setSubmit2"]),
    getValidationState({ dirty, validated, valid = null }) {
      if (this.submit) {
        return dirty || validated ? valid : null;
      }
    },

    getValidationState2({ dirty, validated, valid = null }) {
      if (this.submit2) {
        return dirty || validated ? valid : null;
      }
    },
    registration() {
      localStorage.removeItem("token");

      localStorage.removeItem("username");
      localStorage.removeItem("email");
      let user = {
        email: this.email,
        password: this.password1,
        password2: this.password2,
        username: this.email,
        telegram: this.telegram,
      };
      this.$store
        .dispatch("register", { user })
        .then(() => this.$router.push("/"))
        .catch(
          (err) => (this.errorLogin = err.response.data.error),
          this.setSubmit2(false)
        );
    },
    // swichLangEn() {
    //   this.$i18n.locale = "En";
    //   localize("en", en);
    //   localStorage.setItem("locale", "En");
    // },

    // swichLangRu() {
    //   this.$i18n.locale = "ru";
    //   localize("ru", ru);
    //   localStorage.setItem("locale", "ru");
    // },
  },
};
</script>
