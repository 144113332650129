<template>
  <div style="position: fixed; bottom: 0px; left: 0px; z-index: 100" class="SideBarM DSide1"
    :class="[side == true ? 'SideBarM1' : '']">
    <div class="d-flex divSideM22" :class="[side == true ? 'divSideM2' : '']">
      <div class="avatar">{{ email.slice(0, 1).toUpperCase() }}</div>
      <!-- <div style="position: relative" class="ms-3" @click="uved = !uved">
        <img src="../../assets/img/uved.svg" style="margin-top: 25px" alt="" />
        <div class="uved">0</div>
        <div class="uved22" :class="[uved == true ? 'uved2' : '']">
          <div
            class="d-flex text-center w-100 align-content-center justify-content-center"
          >
            <p class="P2Text text-muted small">
              {{ $t("SideBar.uv1") }}
            </p>
          </div>
        </div>
      </div> -->
      <div class="BalBar ms-4 mt-3">$ {{ balance.toFixed(2) }}</div>
      <!-- <div class="divYASidebar1 ms-auto mb-3">
        <div
          @click="swichLangEn"
          class="d-flex justify-content-center align-items-center"
          style="flex-basis: 72px; cursor: pointer"
        >
          <img src="../../assets/img/en.svg" alt="" />
          <span class="Yaz ms-2">en</span>
        </div>
        <div
          @click="swichLangRu"
          class="d-flex justify-content-center align-items-center ms-3 me-3"
          style="flex-basis: 72px; cursor: pointer"
        >
          <img src="../../assets/img/ru.svg" alt="" />
          <span class="Yaz ms-2">ru</span>
        </div>
      </div> -->
    </div>
    <div class="d-flex justify-content-around divSideM11" :class="[side == true ? 'divSideM1' : '']">
      <!-- <router-link v-if="accounts.length > 0 " to="/download" style="text-decoration: none;" >
            <div class="strmenuM d-flex flex-column align-items-center " @click="side = false">
               <div class="Bar51"></div><span class="SMText" >{{ $t('SideBar.Download') }}</span>
            </div>
        </router-link>-->
      <router-link to="/balance" style="text-decoration: none">
        <div class="strmenuM d-flex flex-column align-items-center" @click="side = false">
          <div class="Bar4"></div>
          <span class="SMText">{{ $t("SideBar.Balance") }}</span>
        </div>
      </router-link>
      <router-link to="/support" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar7"></div>
          <span class="SMText">{{ $t("SideBar.Support") }}</span>
        </div>
      </router-link>
      <router-link to="/affiliate" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar8"></div>
          <span class="SMText">{{ $t("SideBar.Affiliate") }}</span>
        </div>
      </router-link>
      <router-link to="/settings" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar9"></div>
          <span class="SMText">{{ $t("SideBar.Settings") }}</span>
        </div>
      </router-link>
    </div>
    <div class="d-flex justify-content-around divSideM">
      <router-link v-if="forms.length > 0" to="/company" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar6"></div>
          <span class="SMText">{{ $t("SideBar.company") }}</span>
        </div>
      </router-link>
      <router-link to="/plans" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar3"></div>
          <span class="SMText">{{ $t("SideBar.Tariffs") }}</span>
        </div>
      </router-link>
      <router-link exact to="/" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar1"></div>
          <span class="SMText">{{ $t("SideBar.home") }}</span>
        </div>
      </router-link>
      <router-link v-if="accounts.length > 0" to="/accounts" style="text-decoration: none">
        <div class="strmenuM flex-column d-flex align-items-center" @click="side = false">
          <div class="Bar2"></div>
          <span class="SMText">{{ $t("SideBar.Accounts") }}</span>
        </div>
      </router-link>
      <div style="
          width: 70px;
          height: 50px;
          border-radius: 7px;
          background: transparent;
        " class="flex-column d-flex align-items-center" @click="menu">
        <div class="Bar10" :class="[side == true ? 'BarA10' : '']"></div>
        <span class="SMText" style="margin-top: 33px">{{
      $t("SideBar.Menu")
          }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { localize } from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
export default {
  data() {
    return {
      side: false,
      uved: false,
    };
  },
  mounted() {
    this.fetchTemplateBalance();
    this.fetchTemplateInfo();
    this.fetchAccounts();
  },
  computed: {
    ...mapGetters([
      "getVersion",
      "getBalance",
      "getEmail",
      "getAccounts",
      "getForms",
    ]),
    forms: {
      get() {
        return this.getForms;
      },
    },
    accounts: {
      get() {
        return this.getAccounts;
      },
    },
    version: {
      get() {
        return this.getVersion;
      },
    },
    balance: {
      get() {
        return this.getBalance;
      },
    },
    email: {
      get() {
        return this.getEmail;
      },
    },
  },
  methods: {
    menu() {
      this.side = !this.side;
      this.uved = false;
    },
    ...mapActions([
      "fetchTemplateBalance",
      "fetchTemplateInfo",
      "fetchAccounts",
      "fetchCategories",
      "fetchCategories2",
      "fetchGeneralMethods",
      "fetchPlans",
      "setCAT4",
      "setSlugPage",
      "setIdIn",
    ]),
    // swichLangEn() {
    //   this.$i18n.locale = "En";
    //   localize("en", en);
    //   localStorage.setItem("locale", "En");

    //   this.fetchPlans();
    //   this.fetchGeneralMethods();
    //   if (this.$route.params.slug != undefined) {
    //     this.setCAT4(this.$route.params.slug);
    //     this.setSlugPage(this.$route.params.slug);
    //     if (this.$route.params.id != undefined) {
    //       this.setIdIn(this.$route.params.id);
    //     } else {
    //       this.fetchCategories2();
    //     }
    //   } else {
    //     this.fetchCategories();
    //     this.fetchCategories2();
    //   }
    // },

    // swichLangRu() {
    //   this.$i18n.locale = "ru";
    //   localize("ru", ru);
    //   localStorage.setItem("locale", "ru");

    //   this.fetchPlans();
    //   this.fetchGeneralMethods();
    //   if (this.$route.params.slug != undefined) {
    //     this.setCAT4(this.$route.params.slug);
    //     this.setSlugPage(this.$route.params.slug);
    //     if (this.$route.params.id != undefined) {
    //       this.setIdIn(this.$route.params.id);
    //     } else {
    //       this.fetchCategories2();
    //     }
    //   } else {
    //     this.fetchCategories();
    //     this.fetchCategories2();
    //   }
    // },
  },
};
</script>
