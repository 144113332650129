<template>
    <div>
        <div style="height:23px" id="int"></div>
        <b-row >
            <b-col>
                <b-card no-body class="cardA2">
                    <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <div :class="icon" >
                          </div>
                          <h2 class="PTextB ms-3 mt-2">{{name}}</h2>
                    </div>
                    <div class="d-flex mt-2">
                       <router-link to="/support" style="text-decoration:none"> <img src="../../assets/img/cardA21.svg" alt=""><span class="P1Text ms-1 d-none d-sm-inline-block">{{ $t('SideBar.back') }}</span></router-link>
                    </div>
                    </div>
                    <b-row class="mt-3">
                        <b-col xl="4">
                              <b-list-group>
                                <b-list-group-item class="P1Text " :class="[item.id == getId ? 'act':'']" button  @click="body(item.id)" v-for="(item, index) in getCategories2()" :key="index">{{item.text}}</b-list-group-item>
                              </b-list-group>
                        </b-col>
                        <b-col xl="8">
                                <div  v-html="getBady()">
                                </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return{
            page:0
        }
    },
    mounted(){
        this.fetchBady()
    },
    computed:{
          ...mapGetters(
      [
        'getIcon',
        'getName',
        'getId'
      ]
    ),
    icon: {
      get() {
        return this.getIcon
      },
    },
    name: {
      get() {
        return this.getName
      },
    },
    },
    methods:{
        body(id){
            this.setId(id)
        },
        ...mapGetters(
      [
        'getCategories2',
        'getBady'
      ]
    ),
          ...mapActions(
      [
        'setId',
        'fetchBady'
      ]
    ),
    }
}
</script>