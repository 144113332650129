<template>
  <div>
    <b-modal
      id="modalAccountInfo"
      class="model1"
      ref="modalAccountInfo"
      centered
      hide-footer
      hide-header
      size="lg"
      title-sr-only:false
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :no-enforce-focus="true"
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <h2 class="PTextB">{{ $t("acc_info_title") }}</h2>
        <!--<div class="BalBar1">$2</div>-->
      </div>
      <span v-html="$t('acc_info_text')"></span>

      <!-- <b-form-group class="mb-4">
        <b-form-checkbox v-model="acceptInfo" :class="{'danger': not_accept}" >
          {{$t('acc_accept_info')}}
        </b-form-checkbox>
      </b-form-group>-->
      <div class="d-flex justify-content-center mt-2">
        <b-button class="greenbtn" @click="hideModal">
          {{ $t("to_accept") }}</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      msg: null,
      password: null,
      accountData: null,
      valid_pwd: false,
      acceptInfo: false,
      not_accept: false,
    };
  },
  methods: {
    ...mapActions(["getAccountData", "checkPassword"]),
    checkPasswordData() {
      this.checkPassword(this.password)
        .then((response) => {
          this.getAccountData()
            .then((response) => {
              this.valid_pwd = true;
              this.accountData = response.data;
            })
            .catch((error) => {
              this.msg = error.response.data;
            });
        })
        .catch((error) => {
          this.msg = error.response.data;
        });
    },
    hideModal() {
      localStorage.setItem("AccountInfoAccept", true);
      this.$refs["modalAccountInfo"].hide();
    },
  },
};
</script>
