<template>
  <div class="empty">
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 10vh"
    >
      <a
        v-if="$route.path === '/confirmPath'"
        target="_blank"
        href="https://easypayments.online/"
      >
        <img
          src="../../assets/img/logoempty.svg"
          style="width: 150px"
          class="mt-2"
          alt=""
        />
      </a>
      <router-link v-else to="/">
        <img
          src="../../assets/img/logoempty.svg"
          style="width: 150px"
          class="mt-2"
          alt=""
        />
      </router-link>
    </div>
    <div class="empty1">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    swichLangEn() {
      this.$i18n.locale = "En";
      localize("en", en);
      localStorage.setItem("locale", "En");
    },

    swichLangRu() {
      this.$i18n.locale = "ru";
      localize("ru", ru);
      localStorage.setItem("locale", "ru");
    },
  },
};
</script>
