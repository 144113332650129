<template>
  <div>
    <b-modal
      id="modalAccount"
      class="model1"
      ref="modalAccount"
      centered
      hide-footer
      hide-header
      title-sr-only:false
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :no-enforce-focus="true"
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <span v-if="!valid_pwd">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modalAccount.h2") }}</h2>
          <!--<div class="BalBar1">$2</div>-->
        </div>
        <b-form-group content-cols="12" class="mb-3">
          <b-form-input type="password" class="input w-100" v-model="password">
          </b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-center mt-2">
          {{ msg }}
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="checkPasswordData">{{
            $t("modalAccount.btn")
          }}</b-button>
        </div>
      </span>
      <span v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modalAccount.h22") }}</h2>
          <!--<div class="BalBar1">$2</div>-->
        </div>
        <div
          class="d-flex justify-content-start align-items-start flex-column mt-4"
        >
          <p><b>Логин</b>: {{ accountData.login }}</p>
          <p><b>Пароль</b>: {{ accountData.password }}</p>
          <p><b>Email</b>: {{ accountData.email }}</p>
          <p><b>Пароль от почты</b>: {{ accountData.password_email }}</p>
          <p><b>Code 2FA</b>: {{ accountData.code }}</p>
          <p><b>Резервная почта</b>: {{ accountData.reserve_email }}</p>
        </div>
      </span>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      msg: null,
      password: null,
      accountData: null,
      valid_pwd: false,
    };
  },
  methods: {
    ...mapActions(["getAccountData", "checkPassword"]),
    checkPasswordData() {
      this.checkPassword(this.password)
        .then((response) => {
          this.getAccountData()
            .then((response) => {
              this.valid_pwd = true;
              this.accountData = response.data;
            })
            .catch((error) => {
              this.msg = error.response.data;
            });
        })
        .catch((error) => {
          this.msg = error.response.data;
        });
    },
    hideModal() {
      this.$refs["modalAccount"].hide();
      this.valid_pwd = false;
      this.password = null;
      this.msg = null;
      this.accountData = null;
    },
  },
};
</script>
