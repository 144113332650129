<template>
  <div style="min-height: 100vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cardS1">
          <b-row>
            <b-col lg="7">
              <h1 class="H1Heading">{{ $t("support.h1") }}</h1>
              <p class="PText mt-4">{{ $t("support.p1") }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card no-body class="cardS2">
          <h2 class="PTextB">{{ $t("support.h2") }}</h2>
          <b-card no-body class="mb-2 mt-3 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-7>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                {{ $t("support.h41") }}
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-7"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    {{ $t("support.p5") }}
                    <span
                      @click="rout('integration', '33', 'divcardH44', '2')"
                      class="rout"
                      >{{ $t("support.p51") }}</span
                    >
                  </p>
                  <!-- <p >{{ $t('support.p5') }} <a href="/integration/33#int"  class="rout" >{{ $t('support.p51') }}</a></p> -->
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-8>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                {{ $t("support.h42") }}
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-8"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    {{ $t("support.p6") }}
                    <span
                      @click="rout('withdrawal', '38', 'divcardH45', '1')"
                      class="rout"
                      >{{ $t("support.p61") }}</span
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-9>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                {{ $t("support.h43") }}
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-9"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    {{ $t("support.p7") }}
                    <span
                      @click="rout('security', '40', 'divcardH46', '0')"
                      class="rout"
                      >{{ $t("support.p71") }}</span
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-91>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                {{ $t("support.h44") }}
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-91"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    {{ $t("support.p8") }}
                    <span
                      @click="rout('security', '41', 'divcardH46', '0')"
                      class="rout"
                      >{{ $t("support.p81") }}</span
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-92>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                {{ $t("support.h45") }}
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-92"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>{{ $t("support.p9") }}</p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4" v-if="$i18n.locale == 'ru'">
      <b-col>
        <b-card no-body class="cardS2">
          <h2 class="PTextB">Быстрые ответы – Регистрация компании</h2>
          <b-card no-body class="mb-2 mt-3 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-11>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                Сколько времени занимает регистрация?
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-11"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    Обычно процесс регистрации компании с момента оплаты
                    занимает 10-12 рабочих дней. Возможна небольшая задержка в
                    связи с увеличением времени проверки документов в
                    регистарционной палате.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-12>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                Как происходит уплата налогов?
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-12"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    Подробно об уплате налогов написали
                    <span
                      @click="
                        rout('company-registration', '46', 'divcardH47', '6')
                      "
                      class="rout"
                    >
                      в разделе.</span
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-13>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                Как подается финансовая отчетность?
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-13"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    Подробно о подаче финансовой отчетности написали
                    <span
                      @click="
                        rout('company-registration', '47', 'divcardH47', '6')
                      "
                      class="rout"
                    >
                      в разделе.</span
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-131>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                Что входит в обслуживание компании?
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-131"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>
                    В обслуживание компании входит аренда адреса, услуги
                    компании секретаря и сдача бухгалтерской отчетности.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <b-card no-body class="mb-2 cardblockH13">
            <b-row class="rowblockH13" v-b-toggle.accordion-132>
              <div class="back p-0"></div>
              <h4
                class="P1Text mt-2 ms-3 flex_basiscardFAQ"
                style="color: #333333"
              >
                Зачем нужна компания-секретарь?
              </h4>
              <div class="plusback1 mt-2">
                <img src="../assets/img/right.png" class="plus1" alt />
              </div>
            </b-row>
            <b-collapse
              id="accordion-132"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text class="P1Text">
                  <p>Компания-секретать может выполнить следующие действия:</p>
                  <ul>
                    <li>
                      Подготовить индивидуальные учредительные документы
                      (Решение о создании компании, Устав, Учредительный
                      договор).
                    </li>
                    <li>Сменить директора компании.</li>
                    <li>Ввести или вывести одного из акционеров компании.</li>
                    <li>Внести изменения в данные директора или акционера.</li>
                    <li>Увеличить или уменьшить уставный капитал компании.</li>
                    <li>Изменить регистрационный адрес компании.</li>
                    <li>Изменить коды деятельности компании.</li>
                  </ul>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
    <instructions id="int"></instructions>
    <b-row class="mt-4 MBcard">
      <b-col>
        <b-card
          no-body
          class="cardS3 d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <div>
            <h2 class="PTextB">{{ $t("support.h22") }}</h2>
            <p class="P1Text mt-3">
              {{ $t("support.p2") }}
              <a target="_blank" class="RL" href="https://t.me/easypay_sup_bot">
                (@easypay_sup_bot).</a
              >
            </p>
            <p class="P1Text mb-0" v-html="$t('support.p3')"></p>
          </div>
          <a
            target="_blank"
            class="RL mt-3 mt-md-auto widthbtn1"
            href="https://t.me/easypay_sup_bot"
          >
            <b-button class="bluebtn1">{{ $t("support.btn1") }} </b-button>
          </a>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import instructions from "../components/main/Instructions.vue";
export default {
  components: {
    instructions,
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.support"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/support",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["setCAT4", "setSlug3", "setIcon"]),
    rout(slug, id, icon, h1) {
      var data = {
        id: id,
        slug: slug,
      };
      this.setCAT4(h1);
      this.setSlug3(data);
      this.setIcon(icon);

      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
          this.$router.push("/support/" + slug + "/#int");
        } else {
          this.$router.push("/support/" + slug);
          var myelement = document.getElementById("int");
          this.scrollToSmoothly(
            myelement.getBoundingClientRect().top - 100,
            1500
          );
        }
      }

      //
    },

    scrollToSmoothly(pos, time) {
      var currentPos = window.pageYOffset;
      var start = null;
      if (time == null) time = 500;
      (pos = +pos), (time = +time);
      window.requestAnimationFrame(function step(currentTime) {
        start = !start ? currentTime : start;
        var progress = currentTime - start;
        if (currentPos < pos) {
          window.scrollTo(
            0,
            ((pos - currentPos) * progress) / time + currentPos
          );
        } else {
          window.scrollTo(
            0,
            currentPos - ((currentPos - pos) * progress) / time
          );
        }
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      });
    },
  },
  mounted() {},
};
</script>
