<template>
    <div style="min-height:100vh">
        <b-row style="margin-top:10px">
            <b-col>
                <b-card no-body class="cardI1">
                    <b-row>
                        <b-col xl="8">
                            <h1 class="H1Heading">Инструкции</h1>
                            <p class="PText mt-4">В этом разделе мы собрали всю необходимую информацию для работы с нашим сервисом. Выберите интересующий вас раздел или воспользуйтесь поиском.</p>
                            <b-form>
                                <div class="d-flex mt-5">
                                    <b-form-group>
                                        <b-form-input class="input widthInput"></b-form-input>
                                    </b-form-group>
                                    <b-button class="greenbtn1 ms-3">Найти</b-button>
                                </div>
                            </b-form>
                        </b-col>
                        <b-col xl="4">
                            <img src="../assets/img/cardI1.svg" class="cardI1img" alt="">
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <instructions></instructions>
       
    </div>
</template>
<script>
import instructions from '../components/main/Instructions.vue'
export default {
   components:{
    instructions
   }
}
</script>