<template>
  <div style="min-height: 99vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cardP1">
          <div
            class="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center"
          >
            <h1 class="H1Heading">{{ $t("plans.h1") }}</h1>
            <div
              v-if="$i18n.locale == 'ru'"
              class="d-flex"
            >
              <div @click="openModal" class="d-flex">
                <span
                  class="P1Text me-4 me-sm-0"
                  v-if="promocode == undefined"
                  style="cursor: pointer; text-decoration: underline"
                  >{{ $t("plans.d1") }}</span
                >
                <span
                  class="P1Text me-2 me-sm-0"
                  v-else
                  style="
                    cursor: pointer;
                    text-decoration: none;
                    font-family: 'ProximaNova-bold';
                  "
                >
                  <b
                    >Применен промокод
                    <span style="color: #01c9bb">{{ promocode }} </span></b
                  > </span
                ><img
                  v-if="promocode == undefined"
                  src="../assets/img/Arrow1.svg"
                  class="ms-2 me-4 d-none d-sm-block"
                  alt=""
                />
              </div>
              <img
                v-if="promocode != undefined"
                style="cursor: pointer"
                @click="promo"
                class="ms-sm-2 me-3"
                src="../assets/img/promo.svg"
                alt=""
              />
            </div>
          </div>
          <span v-for="(item, index) in getPlans()" :key="index">
            <b-card
              :class="index == 2 ? 'cardplan2' : 'cardplan1'"
              no-body
              v-if="$i18n.locale != 'en' || !item.en"
            >
              <div
                class="d-flex flex-column flex-md-row justify-content-between align-items-md-center align-items-start"
              >
                <h3 class="cena-h3 order-md-1 order-2 mt-3 mt-md-0">
                  {{ item.name }}
                </h3>
                <div class="d-flex flex-column order-md-2 order-1">
                  <div class="d-flex ms-md-auto">
                    <span
                      v-if="item.initial_cost"
                      class="cena-p1 mt-auto mb-2"
                      >{{ $t("from") }}</span
                    >

                    <span class="cena">${{ item.price }}</span>
                  </div>
                  <span class="cena-p1 ms-md-auto"
                    >{{ item.price_terms
                    }}<span v-if="item.type == 'company'"
                      ><b>
                        <span> {{ item.price_to_pay }}</span>
                      </b></span
                    ></span
                  >
                </div>
              </div>
              <b-row>
                <b-col xl="8" lg="6">
                  <b-row>
                    <b-col
                      v-for="(item_desc, index1) in item.short_descriptions"
                      :key="index1"
                      xl="6"
                    >
                      <b-row class="rowblockH102 mt-3">
                        <img
                          src="../assets/img/tarif.svg"
                          style="width: 19px; flex-basis: 10px; padding: 0px"
                          alt=""
                        />
                        <span class="cena-p2 p-0 ms-1">{{
                          item_desc.description
                        }}</span>
                      </b-row>
                    </b-col>
                  </b-row>
                  <p
                    class="cena-p2 mt-3 mt-md-3"
                    v-if="item.description != 'None'"
                    v-html="item.description"
                  ></p>
                </b-col>
                <b-col xl="4" class="d-flex">
                  <b-button
                    class="greenbtn1 mt-3 mt-sm-auto ms-auto"
                    v-if="(item.type == 'company') & item.available"
                    @click="
                      openModal2(
                        item.id,
                        item.price,
                        item.price_terms_value,
                        item.price_terms_variant
                      )
                    "
                    >{{ $t("plans.btn1") }}</b-button
                  >
                  <b-button
                    class="greenbtn1 mt-3 mt-sm-auto ms-auto"
                    v-if="(item.type == 'company') & !item.available"
                    @click="
                      openModalTarifCompany(
                        item.id,
                        item.price,
                        item.price_terms_value,
                        item.price_terms_variant
                      )
                    "
                    >{{ $t("plans.btn1") }}</b-button
                  >
                  <b-button
                    class="greenbtn1 mt-3 mt-sm-auto ms-auto"
                    v-else-if="item.type == 'plan'"
                    @click="
                      openModalTarif(
                        item.id,
                        item.price,
                        item.price_terms_value,
                        item.price_terms_variant
                      )
                    "
                    >{{ $t("plans.btn1") }}</b-button
                  >
                  <b-button
                    class="greenbtn1 mt-3 mt-sm-auto ms-auto"
                    v-else-if="item.type == 'bank'"
                    @click="
                      openModalBank(
                        item.id,
                        item.price,
                        item.price_terms_value,
                        item.price_terms_variant
                      )
                    "
                    >{{ $t("plans.btn1") }}</b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </span>
        </b-card>
      </b-col>
    </b-row>
    <insr1></insr1>
    <!-- <b-row class="mt-4">
      <b-col>
        <b-card class="cardCR MBcard" no-body>
          <b-row class="d-flex justify-content-center">
            <b-col class="d-flex d-md-none">
              <img
                src="../assets/img/logoCR.svg"
                class="mr-auto"
                style="width: 200px; height: 44.44px"
                alt=""
              />
            </b-col>
            <b-col class="col-xxl-7 col-xl-8 col-12 mt-3 mt-md-0">
              <h3 class="PTextB">{{ $t("plans.h3") }}</h3>
              <p class="P1Text mt-3">{{ $t("plans.p1") }}</p>
            </b-col>
            <b-col class="col-xxl-1 d-none d-xxl-block"></b-col>
            <b-col class="col-xl-4 col-12 d-flex mt-3 mt-xl-0 flex-xl-column">
              <img
                src="../assets/img/logoCR.svg"
                class="mt-auto mb-auto ms-xl-auto ms-4 order-1 order-xl-0 d-none d-md-flex"
                style="width: 200px; height: 44.44px"
                alt=""
              />
              <a
                target="_blank"
                class="mt-auto mb-auto ms-xl-auto order-0 order-xl-1 abtn-CR widthCRbtn"
                href="https://cryptocloud.pro/?utm_source=easypayments.online&utm_medium=plans-lk"
              >
                <b-button class="btn-CR widthCRbtn">{{
                  $t("plans.btn2")
                }}</b-button>
              </a>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row> -->
    <modal2></modal2>
    <modal3></modal3>
    <ModalTarif></ModalTarif>
    <ModalEUBANK></ModalEUBANK>
    <ModalTarifCompany></ModalTarifCompany>
    <ModalAccountInfo></ModalAccountInfo>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import insr1 from "../components/main/insr1.vue";
import modal2 from "../components/modal/modal2.vue";
import modal3 from "../components/modal/modal3.vue";
import ModalTarif from "../components/modal/modalTarif.vue";
import ModalEUBANK from "../components/modal/modalEUBANK.vue";
import ModalTarifCompany from "../components/modal/modalTarifCompany";
import ModalAccountInfo from "../components/modal/modalAccountInfo.vue";
export default {
  components: {
    insr1,
    modal2,
    modal3,
    ModalTarif,
    ModalEUBANK,
    ModalTarifCompany,
    ModalAccountInfo,
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.plans"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/plans",
        },
      ],
    };
  },
  data() {
    return {
      password: "ks6atkkANSm8A",
      status: "доступно",
      page: 1,
    };
  },
  mounted() {
    this.fetchAccountsAsync().then((response) => {
      if (!localStorage.getItem("AccountInfoAccept")) {
        if (this.getAccounts().length > 0) {
          this.$root.$emit(
            "bv::show::modal",
            "modalAccountInfo",
            "modalAccountInfo"
          );
        }
      }
    });
    this.fetchPlans();
    // this.setId('23')
    // this.setIcon('divcardH47')
    this.setCAT4("start");
    this.setSlugPage("start");
    this.fetchCategories();
    this.fetchCategories2();
    //this.fetchCategories2()
  },
  computed: {
    ...mapGetters(["getPlan"]),
    promocode() {
      return localStorage.getItem("promocode");
    },
    plan: {
      get() {
        return this.getPlan;
      },
      set(value) {
        this.setPlan(value);
      },
    },
  },
  methods: {
    promo() {
      localStorage.removeItem("promocode");
      window.location.reload();
    },
    ...mapGetters(["getPlans", "getName", "getAccounts"]),
    Plan() {
      this.$router.push("/company");
      this.setPlan(true);
    },
    ...mapActions([
      "fetchPlans",
      "setPlanId",
      "setPrice",
      "setId",
      "setSlug3",
      "setIcon",
      "setName",
      "setCAT4",
      "setSlugPage",
      "fetchCategories",
      "fetchCategories2",
      "fetchAccountsAsync",
    ]),
    openModal() {
      this.$root.$emit("bv::show::modal", "modal2", "#my-modal2");
    },
    openModal2(id, price, value_pay, variant) {
      this.$root.$emit("bv::show::modal", "modal3", "#my-modal3");
      this.setPlanId(id);
      if (variant == "usd") {
        this.setPrice(value_pay);
      } else {
        let price_pay = price / 100;
        price_pay = price_pay * value_pay;

        this.setPrice(price_pay.toFixed(0));
      }
    },
    openModalTarif(id, price, value_pay, variant) {
      this.$root.$emit("bv::show::modal", "modal-tarif", "#modal-tarif");
      this.setPlanId(id);
      if (variant == "usd") {
        this.setPrice(value_pay);
      } else {
        let price_pay = price / 100;
        price_pay = price_pay * value_pay;

        this.setPrice(price_pay.toFixed(0));
      }
    },
    openModalBank(id, price, value_pay, variant) {
      this.$root.$emit("bv::show::modal", "modal-bank", "#modal-bank");
      this.setPlanId(id);
      if (variant == "usd") {
        this.setPrice(value_pay);
      } else {
        let price_pay = price / 100;
        price_pay = price_pay * value_pay;

        this.setPrice(price_pay.toFixed(0));
      }
    },
    openModalTarifCompany(id, price, value_pay, variant) {
      this.$root.$emit(
        "bv::show::modal",
        "modal-tarif-company",
        "#modal-tarif-company"
      );
      this.setPlanId(id);
      if (variant == "usd") {
        this.setPrice(value_pay);
      } else {
        let price_pay = price / 100;
        price_pay = price_pay * value_pay;
        this.setPrice(price_pay);
      }
    },
  },
};
</script>
