<template>
    <b-container fluid class="thank">
        <b-container >
             <b-row align-v="center" align-h="center"  class="mt-sm-4 mt-md-0 rowthank">
                <b-card class="thankcard text-start mt-4 mt-sm-0">
                    <b-row class="">
                        <b-col lg="7" style="position:relative" class="p-0 p-md-2">
                    <h2 class="h2thank">{{ $t('thank.h2') }}</h2>
                    <p class="pthank mt-3 mt-md-4" style="font-size:18px">{{ $t('thank.p1') }}</p>
                    
                            <p class="pthank" style="font-size:18px">{{ $t('thank.p2') }}</p>
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/reg1.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px">{{ $t('thank.s1') }} </span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg2.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px">{{ $t('thank.s2') }} </span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg3.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px">{{ $t('thank.s3') }} </span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg4.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px">{{ $t('thank.s4') }} </span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg5.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px">{{ $t('thank.s5') }} </span>
                            </div>
                            <img src="../assets/img/arrow2.svg" style="width:60px;position:absolute;right:20px;bottom:20px" class="d-none d-lg-block" alt="">
                        </b-col>
                         <b-col lg="5" class="d-flex mt-4 flex-column">
                             <img src="../assets/img/cardH1.svg" class="Thankimg" alt="">
                           <a href="https://easypayments.vip/easypay/#/registration" target="_blank" class="mt-auto w-100"><b-button class="greenbtn ">{{ $t('thank.btn') }}</b-button></a> 
                        </b-col>
                    </b-row>
                </b-card>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default ({
     metaInfo() {
    return {
      title: this.$i18n.t('meta.thank'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online/thank-you' }
      ]
    }
  },
     mounted() {
         this.$i18n.locale = 'ru'
      localStorage.setItem("locale", 'ru')
  },
})
</script>