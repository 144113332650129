<template>
  <div>
    <home></home>
    <ModalAccountInfo></ModalAccountInfo>
  </div>
</template>
<script>
import home from "@/components/main/home.vue";
import { mapActions, mapGetters } from "vuex";
import ModalAccountInfo from "../components/modal/modalAccountInfo.vue";
export default {
  components: { home, ModalAccountInfo },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.home"),
      titleTemplate: "",
      link: [
        { rel: "canonical", href: "https://https://lk.easypayments.online/" },
      ],
    };
  },
  mounted() {
    this.fetchAccountsAsync().then((response) => {
      if (this.getAccounts().length > 0) {
        // this.$root.$emit('bv::show::modal', 'modalAccountInfo', 'modalAccountInfo')
      }
    });
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["fetchAccountsAsync"]),
    ...mapGetters(["getAccounts"]),
  },
};
</script>
