<template>
  <div class="mobile-header">
    <div class="mobile-header__content">
      <router-link to="/">
        <img src="@/assets/img/logo.svg" alt="" />
      </router-link>
      <p class="mobile-header__balance mb-0">$ {{ balance.toFixed(2) }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["getBalance"]),
    balance: {
      get() {
        return this.getBalance;
      },
    },
  },
};
</script>

<style>
.mobile-header {
  margin-bottom: 20px;
}

.mobile-header__content {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-header__balance {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #1484d5;
}
</style>
