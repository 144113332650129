<template>
  <b-container fluid class="cont-pad " style="max-width:1488px">
    <b-row>
      <b-col class="m-0 p-0 Sidewidth Dside">
        <SideBarG v-if="getGaid()"></SideBarG>
        <SideBar v-if="!getGaid()"></SideBar>
      </b-col>
      <SideBarM></SideBarM>
      <MobileHeader class="d-block d-md-none"/>
      <b-col class="m-0 pr-0 pl-1 d-flex">
        <div style="width:100%">
          <transition name="component-fade" mode="out-in" v-if="is_animations">
            <router-view></router-view>
          </transition>
          <router-view v-else></router-view>

        </div>
      </b-col>
    </b-row>
    <Gaid v-if="getGaid()"></Gaid>
  </b-container>
</template>
<script>
import Gaid from '@/components/main/Gaid.vue'
import SideBar from '@/components/main/SideBar.vue'
import SideBarG from '@/components/main/SideBarG.vue'
import SideBarM from '@/components/main/SideBarM.vue'
import { mapActions, mapGetters } from 'vuex'
import MobileHeader from '../../components/main/MobileHeader.vue'
export default {
  components: {
    SideBar, SideBarM, SideBarG, Gaid,
    MobileHeader
},
  data() {
    return {
    };
  },
  methods: {
    ...mapGetters([
      'is_email_confirm',
      'getGaid'
    ]),
  },
  mounted() {
    console.log('EMAIL CONFIRM', this.is_email_confirm());
    setTimeout(() => {
      if (!this.is_email_confirm()) {
        this.$router.push('/user/account/email/confirm')
      }
    },1000)

  },
  computed: {
    is_animations() {
      console.log(this.$route)
      if (this.$route.name == 'in1') {
        return false
      }
      else {
        return true
      }
    }
  }
};
//v-if="window.location.pathname != '/support/:slug'"
</script>