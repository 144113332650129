<template>
  <div style="min-height: 99vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cardA1">
          <h1 class="H1Heading">{{ $t("account.h1") }}</h1>
          <b-card
            v-for="(item, index) in getAccounts()"
            :key="index"
            no-body
            class="mb-4 divcardA1"
          >
            <div class="d-flex" @click="acord(index)">
              <span class="PTextB mt-2">{{ index + 1 }}</span>
              <div v-if="item.status" class="divColl"></div>
              <div v-else class="divColl2"></div>
              <h4 class="P1Text mt-2">{{ item.account.login }}</h4>
              <div class="d-flex mt-2 ms-auto">
                <span
                  class="P1Text btnD3 colaps"
                  style="cursor: pointer"
                  v-if="item.started == 0"
                  >{{ $t("account.s1") }}</span
                >
                <span
                  class="P1Text btnD3 colaps1"
                  style="cursor: pointer"
                  v-else
                  >{{ $t("account.s2") }}</span
                >
                <img
                  src="../assets/img/cardA1.svg"
                  class="plus1 plusback3 ms-3 me-2 me-sm-0 mt-2"
                  :class="[item.started != 0 ? 'plusback31' : '']"
                  alt
                />
              </div>
            </div>
            <b-collapse
              :id="'accordion-' + index"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <b-card-text
                  style="border-top: 0.5px solid rgba(89, 89, 89, 0.3)"
                  class="ms-0 ms-sm-4"
                >
                  <div
                    class="d-flex flex-column flex-sm-row justify-content-between mt-3"
                  >
                    <!--- <span class="P1Text">{{ $t('account.s3') }}: {{ item.account.email.password }}
                                        </span>
                                        <span class="P1Text">{{ $t('account.s5') }}: {{ item.account.email.reserv_email }}
                                        </span>-->
                    <b-button
                      class="greenbtn"
                      @click="openModalPassword(item.status_proxy.stripe_id)"
                      >{{ $t("account.d2") }}</b-button
                    >
                    <!---
                                        <div class="d-flex">
                                            <span class="P1Text">{{ $t('account.s4') }}:
                                            </span>
                                            <div class="divColl divCollmar" v-if="item.status_proxy.str"></div>
                                            <div class="divColl2 divCollmar" v-else></div>
                                        </div>-->
                  </div>
                  <div class="d-flex justify-content-between">
                    <span></span>
                    <!-- <router-link to="#" class="P1Text mt-1 mt-sm-0">{{ $t('account.r1') }}
                                        </router-link> -->
                    <!--- <div @click="openModal(item.status_proxy.stripe_id)" class="RL"
                                            style="text-decoration: underline;">{{
                                                    $t('account.d1')
                                            }}</div>-->
                  </div>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
          <!--  <div class="mt-3 d-flex flex-column flex-sm-row">
                        <b-button @click="fetchAccounts()" class="greenbtn1  widthBtn  ">{{ $t('account.btn1') }}
                        </b-button>
                        <router-link to="/download">
                            <b-button class="greenbtn1 ms-sm-3 widthbtn1 mt-3 mt-sm-0">{{ $t('account.btn2') }}
                            </b-button>
                        </router-link>
                    </div>-->
        </b-card>
      </b-col>
    </b-row>
    <insr1 class="MBcard"></insr1>
    <modal1></modal1>
    <ModalAccount></ModalAccount>
    <ModalAccountInfo></ModalAccountInfo>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import insr1 from "../components/main/insr1.vue";
import modal1 from "../components/modal/modal1.vue";
import ModalAccount from "../components/modal/modalAccount.vue";
import ModalAccountInfo from "../components/modal/modalAccountInfo.vue";
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("meta.Account"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/accounts",
        },
      ],
    };
  },
  components: {
    insr1,
    modal1,
    ModalAccount,
    ModalAccountInfo,
  },
  mounted() {
    this.fetchAccountsAsync().then((response) => {
      //   if (this.getAccounts().length > 0) {
      //     this.$root.$emit(
      //       "bv::show::modal",
      //       "modalAccountInfo",
      //       "modalAccountInfo"
      //     );
      //   }
    });
    var data = {
      id: "29",
      slug: "login",
    };
    // this.setCAT()
    // this.setSlug3(data)
    // this.setIcon('divcardH43')
    // this.fetchAccounts()
    this.setId("22");
    this.setIcon("divcardH43");
    this.setCAT4("start");
    this.setSlugPage("start");
    this.fetchCategories2();
    console.log(this.accordion("accordion-0"));
    this.$root.$on("bv::collapse::state", (collapseId, isJustShown) => {
      //   console.log('collapseId:', collapseId)
      //   console.log('isJustShown:', isJustShown)
    });
  },
  data() {
    return {
      page: 1,
    };
  },
  computed: {
    accordion() {
      return this.getAccordion();
    },
  },
  methods: {
    ...mapActions(["fetchAccountsAsync"]),
    ...mapGetters(["getAccounts"]),
    acord(index) {
      this.$root.$emit("bv::toggle::collapse", "accordion-" + index);
      this.setAccordion(index);
    },
    ...mapGetters(["getAccounts", "getAccordion", "getName"]),
    ...mapActions([
      "fetchAccounts",
      "setAccordion",
      "setSlug3",
      "setIcon",
      "setName",
      "fetchBady",
      "setId",
      "setCAT",
      "setStripeID",
      "setCAT4",
      "fetchCategories2",
      "setSlugPage",
    ]),
    openModal(stripe_id) {
      this.setStripeID(stripe_id);
      this.$root.$emit("bv::show::modal", "modal1", "#my-modal1");
    },
    openModalPassword(stripe_id) {
      this.setStripeID(stripe_id);
      this.$root.$emit("bv::show::modal", "modalAccount", "#modalAccount");
    },
  },
};
</script>
