<template>
  <div style="min-height: 100vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cardAf1">
          <b-row>
            <b-col lg="8">
              <h1 class="H1Heading">{{ $t("affiliate.h1") }}</h1>
              <p class="PText mt-4">{{ $t("affiliate.p1") }}</p>
              <b-form>
                <div class="d-flex flex-column mt-5">
                  <div style="position: relative">
                    <b-form-group>
                      <b-form-input
                        class="input widthInput1"
                        style="padding-right: 30px"
                        readonly
                        :value="getReferralLink()"
                      ></b-form-input>
                    </b-form-group>
                    <img
                      @click="copyAmount()"
                      src="../assets/img/copy.svg"
                      style="
                        position: absolute;
                        top: 14px;
                        right: 7px;
                        cursor: pointer;
                      "
                      alt=""
                    />
                  </div>
                  <p class="P1Text mt-3">
                    {{ $t("affiliate.p2") }}{{
                      $t("affiliate.p3")
                    }}
                  </p>
                </div>
              </b-form>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card no-body class="cardAf2">
          <div class="d-flex align-items-center">
            <h2 class="PTextB">{{ $t("affiliate.h2") }}</h2>
            <span
              class="SText ms-5 d-none d-lg-block"
              style="line-height: 18px; width: 380px"
              >{{ $t("affiliate.s2") }}</span
            >
            <a
              href="https://t.me/ecompayhelp"
              target="_blank"
              class="P1Text ms-auto"
            >
              {{ $t("affiliate.a1")
              }}<img src="../assets/img/cardH5AR.svg" class="ms-1" alt="" />
            </a>
          </div>
          <b-row class="mt-3 d-block d-lg-none text-left">
            <b-col>
              <span class="SText" style="line-height: 18px">{{
                $t("affiliate.s2")
              }}</span>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="4">
              <div class="divAf">
                <img src="../assets/img/divAfimg1.svg" alt="" />
                <span class="P1Text ms-2">{{ $t("affiliate.s3") }}</span>
                <span class="BalBar1 ms-auto">{{ getRegistrations() }}</span>
              </div>
            </b-col>
            <b-col lg="4" class="mt-2 mt-lg-0">
              <div class="divAf">
                <img src="../assets/img/divAfimg2.svg" alt="" />
                <span class="P1Text ms-2"> {{ $t("affiliate.s4") }}</span>
                <span class="BalBar1 ms-auto">{{ getPayments() }}</span>
              </div>
            </b-col>
            <b-col lg="4" class="mt-2 mt-lg-0">
              <div class="divAf">
                <img src="../assets/img/divAfimg3.svg" alt="" />
                <span class="P1Text ms-2">{{ $t("affiliate.s5") }}</span>
                <span class="BalBar1 ms-auto">${{ getPayout() }}</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col>
        <b-card no-body class="cardAf3 MBcard">
          <b-row>
            <b-col lg="6">
              <h2 class="PTextB">{{ $t("affiliate.h22") }}</h2>
              <p class="P2Text mt-3">1. {{ $t("affiliate.p4") }}</p>
              <p class="P2Text mt-2">2. {{ $t("affiliate.p5") }}</p>

              <p class="P2Text mt-2">3. {{ $t("affiliate.p6") }}</p>

              <p class="P2Text mt-2">4. {{ $t("affiliate.p7") }}</p>

              <p class="P2Text mt-2">5. {{ $t("affiliate.p8") }}</p>

              <p class="P2Text mt-2">6. {{ $t("affiliate.p9") }}</p>

              <p class="P2Text mt-2">7. {{ $t("affiliate.p10") }}</p>

              <p class="P2Text mt-2">8. {{ $t("affiliate.p11") }}</p>

              <p class="P2Text mt-2">9. {{ $t("affiliate.p12") }}</p>
              <p class="P2Text mt-2">10. {{ $t("affiliate.p13") }}</p>

              <p class="P2Text mt-2">11. {{ $t("affiliate.p14") }}</p>
            </b-col>
            <b-col lg="6">
              <h2 class="PTextB">{{ $t("affiliate.h23") }}:</h2>
              <p class="P2Text mt-3">1. {{ $t("affiliate.p15") }}</p>
              <p class="P2Text mt-2">2. {{ $t("affiliate.p16") }}</p>

              <p class="P2Text mt-2">3. {{ $t("affiliate.p17") }}</p>

              <p class="P2Text mt-2">4. {{ $t("affiliate.p18") }}</p>

              <p class="P2Text mt-2">5. {{ $t("affiliate.p19") }}</p>

              <p class="P2Text mt-2">6. {{ $t("affiliate.p20") }}</p>

              <p class="P2Text mt-2">7. {{ $t("affiliate.p21") }}</p>

              <p class="P2Text mt-2">8. {{ $t("affiliate.p22") }}</p>
              <h2 class="PTextB mt-5">{{ $t("affiliate.h24") }}</h2>
              <p class="P2Text mt-3">1. {{ $t("affiliate.p23") }}</p>
              <p class="P2Text mt-2">2. {{ $t("affiliate.p24") }}</p>
              <p class="P2Text mt-2">3. {{ $t("affiliate.p25") }}</p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.fetchInfo();
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.affiliate"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/affiliate",
        },
      ],
    };
  },
  components: {
    ...mapGetters([
      "getReferralLink",
      "getRegistrations",
      "getPayments",
      "getPayout",
    ]),
    referral_link: {
      get() {
        return this.getReferralLink;
      },
    },
    registrations: {
      get() {
        return this.getRegistrations;
      },
    },
    payments: {
      get() {
        return this.getPayments;
      },
    },
    payout: {
      get() {
        return this.getPayout;
      },
    },
  },
  methods: {
    ...mapGetters([
      "getReferralLink",
      "getRegistrations",
      "getPayments",
      "getPayout",
    ]),
    copyAmount() {
      this.$clipboard(this.getReferralLink());
    },

    ...mapActions(["fetchInfo"]),
  },
};
</script>
