<template>
  <div style="position: sticky; top: -1px; z-index: 100; height: 750px">
    <div class="D1Sidebar" :class="[side == true ? 'D1Sidebar1' : '']">
      <div class="SideBar d-flex flex-column" style="padding: 10px">
        <div class="d-flex justify-content-center">
          <div class="imgD" :class="[side == true ? 'imgD1' : '']">
            <img src="../../assets/img/logo.svg" class="imgSide" alt="" />
          </div>
        </div>
        <div class="div1Side" :class="[side == true ? 'div1Side1' : '']">
          <b-row class="w-100">
            <b-col cols="4" class="ps-2">
              <div class="avatar">{{ email.slice(0, 1).toUpperCase() }}</div>
            </b-col>
            <b-col cols="8" class="colD ps-0" :class="[side == true ? 'colD1' : '']">
              <!-- <div
                class="align-items-center divuved"
                :class="[side == true ? 'divuved1' : '']"
              >
                <div
                  style="position: relative; cursor: pointer"
                  @click="uved = !uved"
                >
                  <img
                    src="../../assets/img/uved.svg"
                    style="margin-right: 10px"
                    alt=""
                  />
                  <div class="uved" :class="[side == true ? 'uved12' : '']">
                    0
                  </div>
                </div>
                <div class="uved22" :class="[uved == true ? 'uved2' : '']">
                  <div
                    @click="uved = false"
                    style="
                      width: 10px;
                      height: 10px;
                      position: absolute;
                      top: 3px;
                      right: 10px;
                      cursor: pointer;
                    "
                  >
                    <img src="../../assets/img/krest.svg" alt="" />
                  </div>
                  <div
                    class="d-flex text-center w-100 align-content-center justify-content-center"
                  >
                    <p class="P2Text text-muted small">
                      {{ $t("SideBar.uv1") }}
                    </p>
                  </div>
                </div>
              </div> -->
              <div class="BalBar Sdisp" :class="[side == true ? 'Sdisp1' : '']">
                ${{ balance.toFixed(1) }}
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="align-items-center div2Side" :class="[side == true ? 'div2Side1' : '']">
          <div style="position: relative">
            <img src="../../assets/img/uved.svg" alt="" />
            <div class="uved">2</div>
          </div>
          <div class="uved22" :class="[uved == true ? 'uved2' : '']">
            <div @click="uved = false" style="
                width: 10px;
                height: 10px;
                position: absolute;
                top: 3px;
                right: 10px;
                cursor: pointer;
              ">
              <img src="../../assets/img/krest.svg" alt="" />
            </div>
            <div class="d-flex">
              <div class="divColl1" style="flex-basis: 13px"></div>
              <p class="P2Text">{{ $t("SideBar.uv1") }}</p>
            </div>
            <div class="d-flex">
              <div class="divColl1" style="flex-basis: 43px"></div>
              <p class="P2Text">{{ $t("SideBar.uv2") }}</p>
            </div>
          </div>
        </div>
        <div style="overflow: hidden; margin-right: -3px">
          <div class="mail mt-2 Sdisp" :class="[side == true ? 'Sdisp1' : '']">
            {{ email }}
          </div>
        </div>
        <div>
          <div class="strmenu2 d-flex justify-content-start align-items-center mt-3"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar1"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.home") }}</span>
            <div class="gaiddiv" v-if="step == 1">
              <div @click="setGaid(false)" style="
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                  cursor: pointer;
                ">
                <img src="../../assets/img/krest.svg" alt="" />
              </div>
              <div style="position: relative">
                <div class="gaidst"></div>
              </div>
              <h1 class="h1gaid">{{ $t("gaid.h11") }}</h1>
              <p class="P1Text">{{ $t("gaid.p11") }}</p>
              <div class="d-flex">
                <span class="d-flex P2Text ms-auto">{{ $t("gaid.step") }} {{ step }}/5
                  <div @click="step = 2" class="ms-2 divarrright" style="cursor: pointer"></div>
                </span>
              </div>
            </div>
          </div>
          <div class="strmenu2 d-flex justify-content-start align-items-center mt-2"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar3"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.Tariffs") }}</span>
            <div class="gaiddiv" v-if="step == 2">
              <div @click="setGaid(false)" style="
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                  cursor: pointer;
                ">
                <img src="../../assets/img/krest.svg" alt="" />
              </div>
              <div style="position: relative">
                <div class="gaidst"></div>
              </div>
              <h1 class="h1gaid">{{ $t("gaid.h12") }}</h1>
              <p class="P1Text">{{ $t("gaid.p12") }}</p>
              <div class="d-flex">
                <span class="d-flex P2Text ms-auto">{{ $t("gaid.step") }} {{ step }}/5
                  <div @click="step = 1" class="ms-2 divarrleft" style="cursor: pointer"></div>
                  <div @click="step = 3" class="ms-2 divarrright" style="cursor: pointer"></div>
                </span>
              </div>
            </div>
          </div>
          <div class="strmenu2 d-flex justify-content-start align-items-center mt-2"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar4"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.Balance") }}</span>

            <div class="gaiddiv" v-if="step == 3">
              <div @click="setGaid(false)" style="
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                  cursor: pointer;
                ">
                <img src="../../assets/img/krest.svg" alt="" />
              </div>
              <div style="position: relative">
                <div class="gaidst"></div>
              </div>
              <h1 class="h1gaid">{{ $t("gaid.h13") }}</h1>
              <p class="P1Text">{{ $t("gaid.p13") }}</p>
              <div class="d-flex">
                <span class="d-flex P2Text ms-auto">{{ $t("gaid.step") }} {{ step }}/5
                  <div @click="step = 2" class="ms-2 divarrleft" style="cursor: pointer"></div>
                  <div @click="step = 4" class="ms-2 divarrright" style="cursor: pointer"></div>
                </span>
              </div>
            </div>
          </div>
          <div class="strmenu2 d-flex justify-content-start align-items-center mt-2"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar7"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.Support") }}</span>
            <div class="gaiddiv" v-if="step == 4">
              <div @click="setGaid(false)" style="
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                  cursor: pointer;
                ">
                <img src="../../assets/img/krest.svg" alt="" />
              </div>
              <div style="position: relative">
                <div class="gaidst"></div>
              </div>
              <h1 class="h1gaid">{{ $t("gaid.h14") }}</h1>
              <p class="P1Text">{{ $t("gaid.p14") }}</p>
              <div class="d-flex">
                <span class="d-flex P2Text ms-auto">{{ $t("gaid.step") }} {{ step }}/5
                  <div @click="step = 3" class="ms-2 divarrleft" style="cursor: pointer"></div>
                  <div @click="step = 5" class="ms-2 divarrright" style="cursor: pointer"></div>
                </span>
              </div>
            </div>
          </div>
          <div class="strmenu2 d-flex justify-content-start align-items-center mt-2"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar8"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.Affiliate") }}</span>
            <div class="gaiddiv" v-if="step == 5">
              <div @click="setGaid(false)" style="
                  width: 10px;
                  height: 10px;
                  position: absolute;
                  top: 3px;
                  right: 10px;
                  cursor: pointer;
                ">
                <img src="../../assets/img/krest.svg" alt="" />
              </div>
              <div style="position: relative">
                <div class="gaidst"></div>
              </div>
              <h1 class="h1gaid">{{ $t("gaid.h15") }}</h1>
              <p class="P1Text">{{ $t("gaid.p15") }}</p>
              <div class="d-flex">
                <span class="d-flex P2Text ms-auto">{{ $t("gaid.step") }} {{ step }}/5
                  <div @click="step = 4" class="ms-2 divarrleft" style="cursor: pointer"></div>
                  <div @click="setGaid(false)" class="ms-2 Green" style="cursor: pointer">
                    {{ $t("gaid.end") }}
                  </div>
                </span>
              </div>
            </div>
          </div>
          <div class="strmenu d-flex justify-content-start align-items-center mt-2"
            :class="[side == true ? 'strmenu11' : '']">
            <div class="Bar9"></div>
            <span class="P1Text ms-3 spnD2" :class="[side == true ? 'spnD22' : '']">{{ $t("SideBar.Settings") }}</span>
          </div>
        </div>
        <div class="divYASidebar" :class="[side == true ? 'divYASidebar1' : '']">
          <div class="d-flex justify-content-center align-items-center" style="flex-basis: 72px; cursor: pointer">
            <img src="../../assets/img/en.svg" alt="" />
            <span class="Yaz ms-2">en</span>
          </div>
          <div class="d-flex justify-content-center align-items-center" style="flex-basis: 72px; cursor: pointer">
            <img src="../../assets/img/ru.svg" alt="" />
            <span class="Yaz ms-2">ru</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { localize } from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
export default {
  data() {
    return {
      side: true,
      uved: false,
      step: 1,
    };
  },
  mounted() {
    this.fetchTemplateBalance();
    this.fetchTemplateInfo();
    this.fetchAccounts();
  },
  computed: {
    ...mapGetters([
      "getVersion",
      "getBalance",
      "getEmail",
      "getAccounts",
      "getForms",
    ]),
    forms: {
      get() {
        return this.getForms;
      },
    },
    accounts: {
      get() {
        return this.getAccounts;
      },
    },
    version: {
      get() {
        return this.getVersion;
      },
    },
    balance: {
      get() {
        return this.getBalance;
      },
    },
    email: {
      get() {
        return this.getEmail;
      },
    },
  },
  methods: {
    ...mapActions([
      "fetchTemplateBalance",
      "fetchTemplateInfo",
      "fetchAccounts",
      "fetchCategories",
      "fetchCategories2",
      "fetchGeneralMethods",
      "fetchPlans",
      "setCAT4",
      "setSlugPage",
      "setIdIn",
      "setGaid",
    ]),
    swichLangEn() {
      this.$i18n.locale = "En";
      localize("en", en);
      localStorage.setItem("locale", "En");

      this.fetchPlans();
      this.fetchGeneralMethods();
      if (this.$route.params.slug != undefined) {
        this.setCAT4(this.$route.params.slug);
        this.setSlugPage(this.$route.params.slug);
        if (this.$route.params.id != undefined) {
          this.setIdIn(this.$route.params.id);
        } else {
          this.fetchCategories2();
        }
      } else {
        this.fetchCategories();
        this.fetchCategories2();
      }
    },

    swichLangRu() {
      this.$i18n.locale = "ru";
      localize("ru", ru);
      localStorage.setItem("locale", "ru");

      this.fetchPlans();
      this.fetchGeneralMethods();
      if (this.$route.params.slug != undefined) {
        this.setCAT4(this.$route.params.slug);
        this.setSlugPage(this.$route.params.slug);
        if (this.$route.params.id != undefined) {
          this.setIdIn(this.$route.params.id);
        } else {
          this.fetchCategories2();
        }
      } else {
        this.fetchCategories();
        this.fetchCategories2();
      }
    },
  },
};
</script>
