<template>
  <div id="app" >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>
<script>
import EmptyLayout from './assets/layout/EmptyLayout'
import MainLayout from './assets/layout/MainLayout'
import { mapActions, mapGetters} from 'vuex'
import { localize } from "vee-validate";
import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";

export default {
  methods:{
    
        ...mapActions(
      [
        'fetchForms',
        'fetchAccounts',
        'fetchTemplateBalance'
      ]
    ),
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'main') + '-layout'
    }
  },

  mounted() {
    this.fetchForms()
    this.fetchAccounts()
    this.fetchTemplateBalance()
    if (localStorage.getItem('locale') == 'En') {
      localize('en', en)
    }
    else {
      localStorage.setItem('locale', 'ru')
      localize('ru', ru)
    }
  },
  components: {
    EmptyLayout, MainLayout
  }
}
</script>
