<template>
    <div style="min-height:100vh">
        <b-row style="margin-top:10px">
            <b-col>
                <b-card no-body class="cardD1">
                    <b-row>
                        <b-col xl="8" lg="7">
                            <h1 class="H1Heading">{{ $t('download.h1') }}</h1>
                            <p class="PText mt-4">{{ $t('download.p1') }}</p>
                            <div class="divcardD1 mt-4 mt-md-5">
                                <b-button class="greenbtn1" @click="download('win')">{{ $t('download.btn1') }}</b-button>
                                <b-button class="greenbtn1 ms-md-3 mt-2 mt-md-0" @click="download('mac')">{{ $t('download.btn2') }}</b-button>
                            </div>
                        </b-col>
                        <b-col xl="4" lg="5">
                            <img src="../assets/img/cardD1.svg" class="cardD1img" alt="">
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <insr1 class="MBcard"></insr1>
        <ModalAccountInfo></ModalAccountInfo>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import insr1 from '../components/main/insr1.vue'
import ModalAccountInfo from '../components/modal/modalAccountInfo.vue'
export default {
    components:{
    insr1,
    ModalAccountInfo
},
    metaInfo() {
    return {
      title: this.$i18n.t('meta.download'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online/download' }
      ]
    }
  },
     methods:{
         ...mapGetters(
      [
        'getName',
        'getAccounts'
      ]
    ),
        ...mapActions(
      [
        'setId',
        'setSlug3',
        'setIcon',
        'setName',
        'fetchCategories2',
        'setCAT2',
        'setCAT4',
        'setSlugPage',
        'fetchAccountsAsync'
      ]
    ),
    download(ver){
        if(ver == 'mac'){
            window.open('https://easypayments.vip/static/app/EasyInstaller_mac_4.3.zip', '_blank')
        }
        else{

            window.open('https://easypayments.vip/static/app/EasyPayments_win_4.3.zip', '_blank')
        }
    }
    },
    mounted(){
        this.fetchAccountsAsync().then((response)=>{
            if (!localStorage.getItem('AccountInfoAccept')) {
                if(this.getAccounts().length > 0){
                this.$root.$emit('bv::show::modal', 'modalAccountInfo', 'modalAccountInfo')
                }
            }
        })
        this.setCAT2()
        var data = {
            id: '27',
            slug: 'app'
        }
        //this.setId('27')
    //    this.setSlug3(data)
    //         this.setIcon('divcardH42')
                    this.setId('27')
        this.setIcon('divcardH42')
        this.setCAT4('app')
        this.setSlugPage('app')
        this.fetchCategories2()
       // this.fetchCategories2()
    },
    data(){
        return{
            password:'ks6atkkANSm8A',
            status:'доступно',
            page:1
        }
    }
}
</script>