import Vue from 'vue'
import Vuex from 'vuex'
import user from './mod/user'
import account from './mod/account'
import balance from './mod/balance'
import helpcenter from './mod/helpcenter'
import referral from './mod/referral'

Vue.use(Vuex)

export default new Vuex.Store({
  modules:{
    user,
    account,
    balance,
    helpcenter,
    referral
  },
  state: {
    version:1,
    plan:false
  },
  mutations: {
    updatePlan(state, data) {
      state.plan = data;
    },
    updateVersion(state, data) {
      state.version = data;
    },
  },
  actions: {
    setPlan(ctx, data) {
      ctx.commit("updatePlan", data);
    },
    setVersion(ctx, data) {
      ctx.commit("updateVersion", data);
    },
  },
  getters: {
    getVersion: (state) => state.version,
    getPlan: (state) => state.plan,
  }
})
