import axios from "axios";

const apiBaseUrl = "https://lk.easypayments.online/";
console.log(apiBaseUrl);
const config = {
  baseURL: apiBaseUrl,
};

const httpClient = axios.create(config);

const authInterceptor = (config) => {
  const token = localStorage.getItem("token") || null;
  const referral_code = localStorage.getItem("referral_code") || null;
  if (token) {
    config.headers.Authorization = "Token " + token;
  } else {
    config.headers.Authorization = null;
  }
  if (referral_code) {
    config.headers.referral_code = referral_code;
  } else {
    config.headers.referral_code = null;
  }
  return config;
};

const loggerInterceptor = (config) => {
  return config;
};

httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { httpClient };
