<template>
  <div style="min-height: 99vh">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card no-body class="cardC1" id="inst">
          <b-row align-content="stretch">
            <b-col xl="6">
              <b-row align-v="center" class="">
                <b-col>
                  <h1 class="H1Heading">Моя компания</h1>
                </b-col>
              </b-row>

              <p v-if="!get_process_form" class="PText mt-4">
                Для начала процедуры регистрации вашей компании предоставьте
                необходимую информацию и документы.
              </p>
              <p v-else class="PText mt-4">
                В данном разделе вы можете отслеживать статус обработки вашей
                заявки на регистрацию компании.
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" v-for="(item, index) in getForms()" :key="index">
        <b-card class="cardC1 mt-4" no-body v-if="item.status != 'created'">
          <div class="d-flex flex-column flex-sm-row justify-content-between">
            <div class="d-flex flex-column justify-content-start">
              <h2 class="PTextB">Ваша заявка</h2>
              <div class="d-flex mt-sm-4 mt-2">
                <span class="P1TextB">Статус заявки:</span>
                <span class="d-flex">
                  <div class="status1 ms-2"></div>
                  <span class="P1Text ms-2">{{ $t(item.status) }}</span>
                </span>
              </div>
            </div>
            <div class="d-flex flex-column justify-content-end mt-4 mt-sm-0">
              <div class="d-flex">
                <span class="P1TextB">Тариф:</span><span class="P1Text ms-1">{{ item.plan_name }}</span>
              </div>
              <div class="d-flex mt-sm-4 mt-2">
                <span class="P1TextB">Заявка отправлена: </span><span class="P1Text ms-1">{{ item.created_at }}</span>
              </div>
            </div>
          </div>
          <b-row v-if="item.documents.length > 0" class="mt-5">
            <b-col lg="12">
              <p class="P1Text">
                <span class="P1TextB">Комментарий: </span> {{ item.comment }}
              </p>
              <!--<p class="P1Text mt-5">Паспорт (главный разворот и разворот с пропиской).</p>
                            <p class="P1Text mt-5">1.1. Пожалуйста, дайте 3 возможных названия компании, начиная с предпочтительного варианта. Используйте LLP в конце названия.</p>-->
            </b-col>
            <b-col lg="6">
              <p class="P2Text"></p>
            </b-col>
            <div v-for="(item_doc, index1) in item.documents" :key="index1">
              <b-row class="mt-4">
                <b-col lg="6">
                  <p class="P2Text">{{ item_doc.name }}</p>
                </b-col>
                <b-col lg="6">
                  <div class="d-flex" v-if="item_doc.count == 1">
                    <label v-if="statusDoc == null" :for="'file' + item_doc.name + 0 + item.id" class="file"></label>
                    <label v-if="statusDoc == 'success'" :for="'file' + item_doc.name + 0 + item.id"
                      class="file1"></label>
                    <input type="file" class="file" :id="'file' + item_doc.name + 0 + item.id" :doc_id="item_doc.id"
                      :doc_name="item_doc.name" :partner_id="item_doc.partner_id" :code="0"
                      @change="handleFileUpload($event)" />
                  </div>
                  <div class="d-flex" v-if="item_doc.count > 1">
                    <div v-for="(item_doc_count, index2) in item_doc.count" :key="index2">
                      <label @click="codeI(index2)" v-if="statusDoc == null"
                        :for="'file' + item_doc.name + item_doc_count + item.id" class="file mr-3"></label>
                      <label @click="codeI(index2)" v-if="statusDoc == 'success'"
                        :for="'file' + item_doc.name + item_doc_count + item.id" class="file1 mr-3"></label>
                      <input type="file" class="file" :id="'file' + item_doc.name + item_doc_count + item.id"
                        :doc_id="item_doc.id" :doc_name="item_doc.name" :partner_id="item_doc.partner_id"
                        :code="item_doc_count" @change="handleFileUpload($event)" />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row class="mt-3">
              <b-col lg="6" offset-lg="6">
                <div class="d-flex flex-column flex-sm-row justify-content-end align-items-center">
                  <b-button class="greenbtn mt-2 mt-sm-0 widthbtn1" @click="fetchDocuments2(item.id)">
                    Отправить на проверку</b-button>
                </div>
              </b-col>
            </b-row>
          </b-row>
        </b-card>

        <b-card no-body class="cardA2 mt-4" v-else>
          <UK :item="item" v-if="item.plan_name === 'Business Basic' ||
                item.plan_name === 'Business Start' ||
                item.plan_name === 'Business Max' ||
                item.plan_name === 'Business Pro' ||
                item.plan_name === 'Business PayPal' ||
                item.plan_name === 'Business Stripe' ||
                item.plan_name === 'Business UK'
                "></UK>
          <Cyprus :item="item" v-if="item.plan_name === 'Регистрация компании на Кипре'"></Cyprus>
        </b-card>
      </b-col>
    </b-row>
    <insr1 class="MBcard"></insr1>
    <ModalPersonData></ModalPersonData>
  </div>
</template>
<script>
import insr1 from "../components/main/insr1.vue";
import { mapActions, mapGetters } from "vuex";
import ModalPersonData from "../components/modal/modalPersonData.vue";
import UK from "../components/questionnaires/UK.vue";
import Cyprus from "@/components/questionnaires/Cyprus.vue";

export default {
  components: {
    insr1,
    ModalPersonData,
    UK,
    Cyprus,
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.company"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/company",
        },
      ],
    };
  },
  data() {
    return {
      part: 2,
      st: 1,
      sox: true,
      str: true,
      plan1: false,
      status: 2,
      errorPOL: false,
      error_share: "",
      partners: "",
    };
  },
  mounted() {
    this.fetchForms();
    //     this.setCAT3()
    // var data = {
    //     id: '43',
    //     slug: 'company-registration'
    // }
    this.setId("43");
    this.setIcon("divcardH47");
    this.setCAT4("company-registration");
    this.setSlugPage("company-registration");
    this.fetchCategories2();
  },
  methods: {
    ...mapActions(["setPartnerIndex", "setKeyPartner", "setSelectedPartner"]),
    back() {
      this.st = 1;

      this.fetchForms();
    },
    getInfoPartner(index) {
      try {
        let second =
          this.getPartners()[index].data.secondname != undefined
            ? this.getPartners()[index].data.secondname
            : "";
        return (
          this.getPartners()[index].data.firstname +
          " " +
          second +
          " " +
          this.getPartners()[index].data.surename
        );
      } catch {
        return "";
      }
    },
    getInfoPartner2(index) {
      try {
        let second =
          this.getPartners2()[index].data.secondname != undefined
            ? this.getPartners2()[index].data.secondname
            : "";
        return (
          this.getPartners2()[index].data.firstname +
          " " +
          second +
          " " +
          this.getPartners2()[index].data.surename
        );
      } catch {
        return "";
      }
    },
    handleChange() {
      if (this.isOverLimit) {
        const lastIndex = this.Partners.length - 1;
        this.Partners[lastIndex].share =
          100 - (this.total - Number(this.Partners[lastIndex].share));
      }
    },
    openEditInfoPerson(index) {
      this.setPartnerIndex(index);
      this.setKeyPartner();
      this.setSelectedPartner();
      let self = this;
      setTimeout(() => {
        self.$root.$emit("bv::show::modal", "modal-personal-data");
        // self.$bvModal.show("modal-personal-data");
      }, 100);
    },
    percent(share, index) {
      share = parseFloat(share);

      var Partners = this.getPartners();
      var all_share = 0;
      console.log("INDEX", index);
      console.log("Partners.length", Partners.length);
      if (share > 100) {
        Partners[index].share = 100;
        this.setPartners(Partners);
      }
      if (index != 0) {
        if (Partners[index - 1].share >= 100) {
          Partners[index].share = 0;
          this.setPartners(Partners);
        }
      }

      if (index == Partners.length - 1) {
        for (var i = 0; i < Partners.length; i++) {
          if (Partners[i].share != NaN) {
            all_share += parseFloat(Partners[i].share);
          }
        }
        console.log("ALL_SAHRE", all_share);
        if (isNaN(all_share)) {
          all_share = 0;
        }
        console.log("ALL_SAHRE", all_share);
        if (all_share === 0) {
          console.log("SHARE", share);
          if (share <= 100) {
            Partners[index].share = share;
            this.setPartners(Partners);
          } else {
            Partners[index].share = 100;
            this.setPartners(Partners);
          }
        } else {
          if (all_share >= 100) {
            console.log("SHARE1", share);
            if (all_share - share < 0) {
              Partners[index].share = 0;
            } else {
              console.log("SHARE111", all_share - share);
              var new_share = 100 - (all_share - share);
              console.log("new_share", new_share);
              if (new_share + all_share > 100) {
                var new_share2 = 100 - (all_share - share);
                console.log("new_share2", new_share2);
                if (new_share2 < 0) {
                  new_share = 0;
                } else if (new_share2 <= 100) {
                  new_share = 100 - (all_share - share);
                } else {
                  new_share = all_share - share;
                }
              }
              console.log("new_share", new_share);
              console.log("all_share ", all_share);
              if (new_share < 0) {
                new_share = 0;
              }
              Partners[index].share = new_share;
            }
            this.setPartners(Partners);
          } else {
            if (all_share - share > 0) {
              var new_share = 100 - (all_share - share);
              if (new_share <= 0) {
                new_share = 0;
              }
              Partners[index].share = new_share;
            } else {
              Partners[index].share = share;
            }
            this.setPartners(Partners);
          }
          all_share = 0;
          for (var i = 0; i < Partners.length; i++) {
            if (Partners[i].share != NaN) {
              all_share += parseFloat(Partners[i].share);
            }
          }
          if (all_share > 100) {
            this.percent(new_share, index);
          }
        }
      }
    },
    anket() {
      this.fetchDocuments2();
      this.fetchForms();
    },
    codeI(index2) {
      this.setCode(index2);
    },
    //Обработчик загрузки, в нем можно добавить изменение внешнего вида кнопки загрузить
    handleFileUpload(event) {
      console.log("DOC_ID", event.target.doc_id);
      var file = event.target.files[0]; //Получение бинарного файла
      var data = {
        file: file,
        partner_id: event.target.getAttribute("partner_id"),
        document_id: event.target.getAttribute("doc_id"),
        code:
          event.target.getAttribute("doc_name") +
          "_" +
          event.target.getAttribute("code"),
      };
      this.uploadFile(data); //Вызов экшена для отправки фала на сервер
      try {
        event.target.previousSibling.previousSibling.classList.add("file1");
      } catch {
        event.target.previousSibling.classList.add("file1");
      }
    },
    dobab() {
      this.addNewElementPartners();
    },
    udab(index) {
      this.udalElementPartners(index);
    },
    savedForm(paid_plan_id) {
      this.sox = false;
      this.fetchSendForm(paid_plan_id);

      localStorage.setItem("names", names);
      localStorage.setItem("description", description);
      localStorage.setItem("codes", codes);
      localStorage.setItem("other_second_name", other_second_name);
      localStorage.setItem("persons_control", persons_control);
      localStorage.setItem("responsible_partners", responsible_partners);
      localStorage.setItem("partners", partners);

      this.fetchForms();
    },
    stV(
      names = "",
      description = "",
      codes = "",
      other_second_name = "",
      persons_control = "",
      responsible_partners = "",
      paid_plan_id = ""
    ) {
      var all_share = 0;
      this.error_share = "";
      this.errorPOL = false;
      for (var i = 0; i < this.getPartners().length; i++) {
        if (this.getPartners()[i].share != NaN) {
          all_share += parseFloat(this.getPartners()[i].share);
        }
      }
      if (all_share != 100) {
        this.error_share = "Сумма всех долей должна быть 100%";
        return;
      }
      if (
        names != "" &&
        description != "" &&
        codes != "" &&
        persons_control != "" &&
        responsible_partners != ""
      ) {
        document.getElementById("inst").scrollIntoView();
        this.fetchSendForm(paid_plan_id);
        this.errorPOL = false;
        this.st = 2;
        this.sox = false;
      } else {
        this.errorPOL = true;
      }
      localStorage.setItem("names", names);
      localStorage.setItem("description", description);
      localStorage.setItem("codes", codes);
      localStorage.setItem("other_second_name", other_second_name);
      localStorage.setItem("persons_control", persons_control);
      localStorage.setItem("responsible_partners", responsible_partners);
      localStorage.setItem("partners", partners);
    },
    Plan1(form_id) {
      if (form_id != undefined) {
        this.setFormId(form_id);
      }
      if (this.getUploaded_document().length == this.getColM()) {
        document.getElementById("inst").scrollIntoView();
        this.sendDocuments();
        this.fetchForms();
        this.fetchDocuments();
        this.plan1 = false;
        this.str = false;
      } else {
        this.errorPOL = true;
      }
    },
    ...mapActions([
      "setPlanId",
      "sendDocuments",
      "setNames",
      "setDescription",
      "setCodes",
      "setPersonsControl",
      "setOtherSecondName",
      "setPartners",
      "fetchSendForm",
      "setResponsiblePartners",
      "addNewElementPartners",
      "udalElementPartners",
      "fetchForms",
      "uploadFile",
      "setCode",
      "fetchDocuments",
      "fetchDocuments2",
      "setCAT3",
      "setIcon",
      "setSlug3",
      "setSlugPage",
      "setId",
      "setCAT4",
      "fetchCategories2",
      "setFormId",
    ]),
    ...mapGetters([
      "getPartners",
      "getPartners2",
      "getForms",
      "getColM",
      "getUploaded_document",
    ]),
  },
  computed: {
    ...mapGetters([
      "getPlanId",
      "getNames",
      "getDescription",
      "getCodes",
      "getPersonsControl",
      "getOtherSecondName",
      "getResponsiblePartners",
      "getStatusDoc",
      "getKeyPartner",
    ]),
    total() {
      let sum = 0;
      this.Partners.forEach((field) => {
        sum += Number(field.share);
      });
      return sum;
    },
    isOverLimit() {
      return this.total > 100;
    },
    diff() {
      return this.total - 100;
    },
    statusDoc: {
      get() {
        return this.getStatusDoc;
      },
    },
    keyPartner() {
      return this.getKeyPartner;
    },
    get_process_form() {
      var forms = this.getForms().find((item) => item.status !== "created");
      console.log("FORMS", forms);
      try {
        if (forms.length < this.getForms().length) {
          return false;
        } else {
          return true;
        }
      } catch {
        return false;
      }
    },
    responsible_partners: {
      get() {
        return this.getResponsiblePartners;
      },
      set(responsible_partners) {
        this.setResponsiblePartners(responsible_partners);
      },
    },
    names: {
      get() {
        return this.getNames;
      },
      set(names) {
        this.setNames(names);
      },
    },
    description: {
      get() {
        return this.getDescription;
      },
      set(description) {
        this.setDescription(description);
      },
    },
    codes: {
      get() {
        return this.getCodes;
      },
      set(codes) {
        this.setCodes(codes);
      },
    },
    persons_control: {
      get() {
        return this.getPersonsControl;
      },
      set(persons_control) {
        this.setPersonsControl(persons_control);
      },
    },
    other_second_name: {
      get() {
        return this.getOtherSecondName;
      },
      set(other_second_name) {
        this.setOtherSecondName(other_second_name);
      },
    },
  },
};
</script>
