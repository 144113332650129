<template>
  <div style="position: sticky; top: -1px; z-index: 100; height: 750px">
    <div class="D1Sidebar" :class="[side == true ? 'D1Sidebar1' : '']">
      <div class="SideBar d-flex flex-column "
        :class="[sizeWindow == true ? 'justify-content-around' : 'justify-content-start']" style="padding: 10px">
        <div class="d-flex justify-content-center flex-column items-center">
          <router-link to="/" class="imgD" :class="[side == true ? 'imgD1' : '']">
            <img src="../../assets/img/logo.svg" class="imgSide" alt="" />
          </router-link>
          <div class="btnSide" @click="side = !side" :class="[side == true ? 'btnSide1' : '']">
            <img src="../../assets/img/cardH5AR.svg" alt="" />
          </div>
          <b-row class="w-100">
            <b-col cols="4" class="ps-0 ps-0">
              <div class="avatar">{{ email.slice(0, 1).toUpperCase() }}</div>
            </b-col>
            <b-col cols="8" class="colD ps-0" :class="[side == true ? 'colD1' : '']">

              <div class="BalBar Sdisp" :class="[side == true ? 'Sdisp1' : '']">
                $ {{ balance.toFixed(1) }}
              </div>
            </b-col>
            <div style="overflow: hidden; margin-right: -3px">
              <div class="mail mt-2 Sdisp" :class="[side == true ? 'Sdisp1' : '']">
                {{ email }}
              </div>
            </div>
          </b-row>
        </div>

        <!-- <div class="align-items-center div2Side" :class="[side == true ? 'div2Side1' : '']">
          <div style="position: relative" @click="uved = !uved">
            <img src="../../assets/img/uved.svg" alt="" />
            <div class="uved">0</div>
          </div>
          <div class="uved22" :class="[uved == true ? 'uved2' : '']">
            <div @click="uved = false" style="
                width: 10px;
                height: 10px;
                position: absolute;
                top: 3px;
                right: 10px;
                cursor: pointer;
              ">
              <img src="../../assets/img/krest.svg" alt="" />
            </div>
            <div class="d-flex text-center w-100 align-content-center justify-content-center">
              <p class="P2Text text-muted small">
                {{ $t("SideBar.uv1") }}
              </p>
            </div>
          </div>
        </div> -->

        <div class="scroll">
          <router-link exact to="/" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-3"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar1"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.home") }}</span>
            </div>
          </router-link>
          <router-link v-if="forms.length > 0" to="/company" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar6"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.company") }}</span>
            </div>
          </router-link>
          <router-link v-if="accounts.length > 0" to="/accounts" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar2"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Accounts") }}</span>
            </div>
          </router-link>
          <router-link to="/plans" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar3"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Tariffs") }}</span>
            </div>
          </router-link>
          <router-link to="/balance" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar4"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Balance") }}</span>
            </div>
          </router-link>
          <!-- <router-link v-if="accounts.length > 0" to="/download" style="text-decoration: none;">
                        <div class="strmenu d-flex justify-content-start align-items-center mt-2"
                            :class="[side == true ? 'strmenu1' : '']">
                            <div class="Bar5"></div><span class="P1Text ms-3 spnD"
                                :class="[side == true ? 'spnD1' : '']">{{ $t('SideBar.Download') }}</span>
                        </div>
                    </router-link>-->
          <router-link to="/support" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar7"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Support") }}</span>
            </div>
          </router-link>
          <router-link to="/affiliate" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar8"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Affiliate") }}</span>
            </div>
          </router-link>
          <router-link to="/settings" style="text-decoration: none">
            <div class="strmenu d-flex justify-content-start align-items-center mt-2"
              :class="[side == true ? 'strmenu1' : '']">
              <div class="Bar9"></div>
              <span class="P1Text ms-3 spnD" :class="[side == true ? 'spnD1' : '']">{{ $t("SideBar.Settings") }}</span>
            </div>
          </router-link>
        </div>
        <b-row align-h="center" v-if="side" class="d-flex d-xxl-none links-police">
          <div class="text-center">
            <a class="terms-link" target="_blank"
              href="https://lk.easypayments.online/static/offer/%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0%20EasyPayments.pdf">Договор
              оферты</a>
          </div>
          <div class="text-center mt-2">
            <a class="terms-link" target="_blank" href="https://easypayments.online/terms">Пользовательское
              соглашение</a>
          </div>
          <div class="text-center mt-2">
            <a class="terms-link" target="_blank" href="https://easypayments.online/payment-terms">Условия оплаты</a>
          </div>
        </b-row>
        <b-row align-h="center" class="d-none d-xxl-flex links-police">
          <div class="text-center">
            <a class="terms-link" target="_blank"
              href="https://lk.easypayments.online/static/offer/%D0%9E%D1%84%D0%B5%D1%80%D1%82%D0%B0%20EasyPayments.pdf">Договор
              оферты</a>
          </div>
          <div class="text-center mt-2">
            <a class="terms-link" target="_blank" href="https://easypayments.online/terms">Пользовательское
              соглашение</a>
          </div>
          <div class="text-center mt-2">
            <a class="terms-link" target="_blank" href="https://easypayments.online/payment-terms">Условия оплаты</a>
          </div>
        </b-row>
        <!-- <div
          class="divYASidebar"
          :class="[side == true ? 'divYASidebar1' : '']"
        >
          <div
            @click="swichLangEn"
            class="d-flex justify-content-center align-items-center"
            style="flex-basis: 72px; cursor: pointer"
          >
            <img src="../../assets/img/en.svg" alt="" />
            <span class="Yaz ms-2">en</span>
          </div>
          <div
            @click="swichLangRu"
            class="d-flex justify-content-center align-items-center"
            style="flex-basis: 72px; cursor: pointer"
          >
            <img src="../../assets/img/ru.svg" alt="" />
            <span class="Yaz ms-2">ru</span>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { localize } from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";
export default {
  data() {
    return {
      side: false,
      uved: false,
      windowWidth: window.outerWidth
    };
  },
  mounted() {
    this.fetchTemplateBalance();
    this.fetchTemplateInfo();
    this.fetchAccounts();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  computed: {
    ...mapGetters([
      "getVersion",
      "getBalance",
      "getEmail",
      "getAccounts",
      "getForms",
    ]),
    forms: {
      get() {
        return this.getForms;
      },
    },
    accounts: {
      get() {
        return this.getAccounts;
      },
    },
    version: {
      get() {
        return this.getVersion;
      },
    },
    balance: {
      get() {
        return this.getBalance;
      },
    },
    email: {
      get() {
        return this.getEmail;
      },
    },
    sizeWindow: {
      get() {
        return this.windowWidth > 1400;
      },
    },
  },

  methods: {
    ...mapActions([
      "fetchTemplateBalance",
      "fetchTemplateInfo",
      "fetchAccounts",
      "fetchCategories",
      "fetchCategories2",
      "fetchGeneralMethods",
      "fetchPlans",
      "setCAT4",
      "setSlugPage",
      "setIdIn",
    ]),
    handleResize() {
      this.windowWidth = window.outerWidth;
    }
    // swichLangEn() {
    //   this.$i18n.locale = "En";
    //   localize("en", en);
    //   localStorage.setItem("locale", "En");

    //   this.fetchPlans();
    //   this.fetchGeneralMethods();
    //   if (this.$route.params.slug != undefined) {
    //     this.setCAT4(this.$route.params.slug);
    //     this.setSlugPage(this.$route.params.slug);
    //     if (this.$route.params.id != undefined) {
    //       this.setIdIn(this.$route.params.id);
    //     } else {
    //       this.fetchCategories2();
    //     }
    //   } else {
    //     this.fetchCategories();
    //     this.fetchCategories2();
    //   }
    // },

    // swichLangRu() {
    //   this.$i18n.locale = "ru";
    //   localize("ru", ru);
    //   localStorage.setItem("locale", "ru");

    //   this.fetchPlans();
    //   this.fetchGeneralMethods();
    //   if (this.$route.params.slug != undefined) {
    //     this.setCAT4(this.$route.params.slug);
    //     this.setSlugPage(this.$route.params.slug);
    //     if (this.$route.params.id != undefined) {
    //       this.setIdIn(this.$route.params.id);
    //     } else {
    //       this.fetchCategories2();
    //     }
    //   } else {
    //     this.fetchCategories();
    //     this.fetchCategories2();
    //   }
    // },
  },
};
</script>
