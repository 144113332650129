<template>
    <b-container fluid class="thank">
        <b-container style="height:100vh">
            <b-row align-v="center" align-h="center" style="height:90vh">
                <b-col xl="7" md="10">
                <b-card class="thankcard text-center">
                    <h2 class="h2thank">Something went wrong...</h2>
                    <p class="pthank mt-4">The page you requested cannot be found. Please, go back to the home page</p>
                    <b-row class="mt-5 d-flex justify-content-center">
                        <b-col md="8">
                           <router-link to="/" ><b-button class="greenbtn">Home page</b-button></router-link>
                        </b-col>
                    </b-row>
                </b-card>
                </b-col>
            </b-row>
        </b-container>
    </b-container>
</template>
<script>
export default {
    metaInfo() {
    return {
      title: this.$i18n.t('meta.err'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online' }
      ]
    }
  },
}
</script>