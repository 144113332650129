<template>
    <div>
        <b-card no-body class="cardR">
            <div class="d-flex ">
                <h1 class="H1Heading ms-auto me-auto">{{ $t('email.confirm_email') }}</h1>
            </div>
            <div class="d-flex mt-3 text-center">
                <p class="PText ms-auto me-auto">{{ $t('email.p_confirm_email') }}</p>
            </div>

            <div class="d-flex mt-2 mb-0 text-center">
                <small class="ms-auto me-auto fs-12 text-muted">{{ msg }}</small>
            </div>
            <b-row class=" mt-3 mt-sm-5">

                <b-col lg="12" class="d-flex align-items-center justify-content-center">

                    <b-button class="greenbtn ms-1 me-1" @click="resend">{{ $t('email.resend') }}</b-button>
                    <b-button class="greenbtn ms-1 me-1" @click="check">{{ $t('email.check') }}</b-button>
                    <!-- Добавить действие отправки письма -->
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-5">
                <div @click="swichLangEn" class="d-flex justify-content-center align-items-center"
                    style="flex-basis: 72px;cursor:pointer">
                    <img src="../assets/img/en.svg" alt=""> <span class="Yaz ms-2">en</span>
                </div>
                <div @click="swichLangRu" class="d-flex justify-content-center align-items-center"
                    style="flex-basis: 72px;cursor:pointer">
                    <img src="../assets/img/ru.svg" alt=""> <span class="Yaz ms-2">ru</span>
                </div>
            </div>
        </b-card>
    </div>
</template>
<script>
import { mapActions} from 'vuex'
import { httpClient } from "../HttpClientV1/HttpClient"
import {
    localize,
} from "vee-validate";

import ru from "vee-validate/dist/locale/ru.json";
import en from "vee-validate/dist/locale/en.json";

export default {
    metaInfo() {
    return {
      title: this.$i18n.t('meta.emailconfirm'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online/user/account/email/confirm' }
      ]
    }
  },
    data() {
        return {
            msg: null,
            success: true
        }
    },
    methods: {
         ...mapActions(
      [
        'setGaid'
      ]
    ),
        resend() {
            httpClient.get('easypay/api/v4/users/send_confirm?locale=' + localStorage.getItem('locale').toLocaleLowerCase())
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.msg = response.data.msg
                    }
                    else {
                        this.msg = response.data.msg
                    }
                })
        },
        check() {
            httpClient.get('easypay/api/v4/users/check_confirm?locale=' + localStorage.getItem('locale').toLocaleLowerCase())
                .then((response) => {
                    if (response.data.status == 'ok') {
                        this.setGaid(true)
                        this.$router.push('/')
                    }
                    else {
                        this.msg = response.data.msg
                    }
                })
        },
        swichLangEn() {
            this.$i18n.locale = 'En'
            localize('en', en)
            localStorage.setItem("locale", 'En')
        },

        swichLangRu() {
            this.$i18n.locale = 'ru'
            localize('ru', ru)
            localStorage.setItem("locale", 'ru')
        }
    },
    mounted() {
      if(this.$route.query.action == 'resend'){
          this.resend()

      }
    }
}
</script>