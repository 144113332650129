import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import accaunt from '../views/account.vue'
import plans from '../views/plans.vue'
import balance from '../views/balance.vue'
import download from '../views/download.vue'
import instructions from '../views/instructions.vue'
import in1 from '../views/in1.vue'
import support from '../views/support.vue'
import affiliate from '../views/affiliate.vue'
import settings from '../views/settings.vue'
import registration from '../views/registration.vue'
import authorization from '../views/authorization.vue'
import password from '../views/password.vue'
import company from '../views/company.vue'
import thank from '../views/thank-you.vue'
import thank2 from '../views/thank-you2.vue'
import confirmPay from '../views/confirmPay.vue'
import accountActive from '../views/accountActive.vue'
import EmailConfirm from '../views/EmailConfirm.vue'
import error from '../views/error.vue'
import store from '../store'
Vue.use(VueRouter)

const routes = [
  {
    path: '/user/account/activate/:key',
    name: 'accountActive',
    meta:{requiresAuth: false,layout:'empty'},
    component: accountActive
  },
  {
    path: '/user/account/email/confirm',
    name: 'EmailConfirm',
    meta:{requiresAuth: false,layout:'empty'},
    component: EmailConfirm
  },
  {
    path: '/confirmPay',
    name: 'confirmPay',
    meta:{requiresAuth: false,layout:'empty'},
    component: confirmPay
  },
  {
    path: '/thank-you-account',
    name: 'reset-password',
    meta:{requiresAuth: false,layout:'empty'},
    component: thank2
  },
  {
    path: '/thank-you',
    name: 'reset-password2',
    meta:{requiresAuth: false,layout:'empty'},
    component: thank
  },
  {
    path: '/company',
    name: 'company',
    meta:{requiresAuth: true,layout:'main'},
    component: company
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta:{requiresAuth: false,layout:'empty'},
    component: password
  },
  {
    path: '/authorization',
    name: 'authorization',
    meta:{requiresAuth: false,layout:'empty'},
    component: authorization
  },
  {
    path: '/registration',
    name: 'registration',
    meta:{requiresAuth: false,layout:'empty'},
    component: registration
  },
  {
    path: '/settings',
    name: 'settings',
    meta:{requiresAuth: true,layout:'main'},
    component: settings
  },
  {
    path: '/affiliate',
    name: 'affiliate',
    meta:{requiresAuth: true,layout:'main'},
    component: affiliate
  },
  {
    path: '/support',
    name: 'support',
    meta:{requiresAuth: true,layout:'main'},
    component: support
  },
  {
    path: '/support/:slug/:id',
    name: 'in1',
    meta:{requiresAuth: true,layout:'main'},
    component: in1
  },
  {
    path: '/support/:slug',
    name: 'in1',
    meta:{requiresAuth: true,layout:'main'},
    component: in1
  },
  {
    path: '/instructions',
    name: 'instructions',
    meta:{requiresAuth: true,layout:'main'},
    component: instructions
  },
  {
    path: '/download',
    name: 'download',
    meta:{requiresAuth: true,layout:'main'},
    component: error
  },
  {
    path: '/balance',
    name: 'balance',
    meta:{requiresAuth: true,layout:'main'},
    component: balance
  },
  {
    path: '/plans',
    name: 'plans',
    meta:{requiresAuth: true,layout:'main'},
    component: plans
  },
  {
    path: '/accounts',
    name: 'accaunt',
    meta:{requiresAuth: true,layout:'main'},
    component: accaunt
  },
  {
    path: '/',
    name: 'Home',
    meta:{requiresAuth: true,layout:'main'},
    component: Home
  },
  { 
      path: '/*', 
      name: 'error', 
      meta:{requiresAuth: false,layout:'empty'},
      component:error 
  },
]

const router = new VueRouter({
  mode: "history",
  scrollBehavior: (to,from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth', };
    }
    else if (to.path.split('/')[1] == 'support'){
      return savedPosition
    }
    else {
      return { x: 0, y: 0 };
    }
  },
  linkActiveClass: "active",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  console.log(to.query.utm_source)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next("/authorization");
  } else {
    next();

  }
  if(to.query.ref){   
    localStorage.setItem('referral_code',to.query.ref)
  }
  if(to.query.utm_source){
    localStorage.setItem('utm_source', to.query.utm_source)
    localStorage.setItem('utm_medium', to.query.utm_medium)
    localStorage.setItem('utm_campaign', to.query.utm_campaign)
    localStorage.setItem('utm_content', to.query.utm_content)
    console.log('ROUTE QUERY',to.query);
  }
  next()

});

export default router;
