<template>
  <div>
    <b-modal
      id="modal3"
      class="model3"
      ref="my-modal3"
      centered
      hide-footer
      hide-header
      title-sr-only:false
    >
      <div
        @click="hideModal"
        style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        "
      >
        <img src="../../assets/img/krest.svg" alt="" />
      </div>
      <span v-if="getBalance >= price">
        <span v-if="!success">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="PTextB">{{ $t("modal3.h2") }}</h2>
            <div class="BalBar1">${{ price }}</div>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <p class="P1Text">{{ $t("modal3.p") }} ${{ price }}</p>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <b-button class="greenbtn" @click="account()">{{
              $t("modal3.btn")
            }}</b-button>
          </div>
        </span>
        <span v-else>
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="PTextB">{{ $t("modalBank.h2") }}</h2>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <p class="P1Text" v-html="$t('modal3.p1')"></p>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <b-button class="greenbtn" @click="toCompany()">{{
              $t("modal3.zapolnit")
            }}</b-button>
          </div>
        </span>
      </span>
      <span v-else-if="success">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modalBank.h2") }}</h2>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="P1Text" v-html="$t('modal3.p1')"></p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="toCompany()">{{
            $t("modal3.zapolnit")
          }}</b-button>
        </div>
      </span>
      <span v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modal3.h2") }}</h2>
          <div class="BalBar1" v-if="getPlanId != 19">${{ price  }}</div>
          <div class="BalBar1" v-else>${{ price }}</div>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="P1Text">{{ $t("modal3.p3") }}</p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="toBalance()">{{
            $t("modal3.btn_balance")
          }}</b-button>
        </div>
      </span>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      success: false,
    };
  },
  mounted() {
    this.fetchPlans();
  },
  computed: {
    ...mapGetters(["getPrice", "getBalance", "getPlanId"]),
    price: {
      get() {
        return this.getPrice;
      },
    },
  },
  methods: {
    ...mapActions(["fetchBuyPlan", "setPaidPlanId", "fetchPlans"]),
    toCompany() {
      this.$router.push("/company");
    },
    toBalance() {
      this.$router.push("/balance");
    },
    account() {
      this.fetchBuyPlan()
        .then((response) => {
          console.log("bued");
          if (response.data.status == "success") {
            this.success = true;
          }
        })
        .catch((err) => {
          this.success = false;
        });
    },
    hideModal() {
      this.$refs["my-modal3"].hide();
    },
  },
};
</script>
