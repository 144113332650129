<template>
    <b-container fluid class="thank">
        <b-container >
            <b-row align-v="center" align-h="center"  class="mt-sm-4 mt-md-0 rowthank">
                <b-card class="thankcard text-start mt-4 mt-sm-0">
                    <b-row class="">
                        <b-col lg="7" style="position:relative" class="p-0 p-md-2">
                    <h2 class="h2thank">Перейдите к&#160;регистрации в&#160;личном кабинете</h2>
                    <p class="pthank mt-3 mt-md-4" style="font-size:18px">Ваша заявка отправлена. Мы свяжемся с вами в ближайшее время.</p>
                    
                            <p class="pthank " style="font-size:18px">Для начала процедуры регистрации компании перейдите в&#160;личный кабинет:</p>
                            <div class="d-flex align-items-center">
                                <img src="../assets/img/reg1.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px"> Нажмите на кнопку</span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg2.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px"> Зарегистрируйтесь в&#160;личном кабинете</span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg3.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px"> Выберите и оплатите нужный вам тариф</span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg4.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px"> Предоставьте необходимые документы</span>
                            </div>
                            <div class="d-flex mt-3 mt-md-2 align-items-center">
                                <img src="../assets/img/reg5.svg" style="width: 20px;" alt="">
                                <span class="pthank ms-2" style="font-size:18px"> Ожидайте регистрации вашей компании!</span>
                            </div>
                            <img src="../assets/img/arrow2.svg" style="width:60px;position:absolute;right:20px;bottom:20px" class="d-none d-lg-block" alt="">
                        </b-col>
                         <b-col lg="5" class="d-flex mt-4 flex-column">
                             <img src="../assets/img/cardH1.svg" class="Thankimg" alt="">
                           <a href="https://easypayments.vip/easypay/#/registration" target="_blank" class="mt-auto  ms-auto me-auto ms-md-0 me-md-0 w-100"><b-button class="greenbtn " >Перейти к регистрации</b-button></a> 
                        </b-col>
                    </b-row>
                </b-card>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default ({
    metaInfo() {
    return {
      title: this.$i18n.t('meta.thank'),
      titleTemplate: "",
      link: [
        { rel: 'canonical', href: 'https://https://lk.easypayments.online/thank-you-account' }
      ]
    }
  },
     mounted() {
         this.$i18n.locale = 'ru'
      localStorage.setItem("locale", 'ru')
  },
     metaInfo:{
        title: 'Перейдите к подключению Stripe – Easy Payments', // set a title
        titleTemplate: '', // %s  required
        htmlAttrs: {
            lang: "ru",
            amp: undefined // "amp" has no value
        },
        meta: [
              {
                property: 'og:url',
                content: 'https://easypayments.online/thank-you-account',
                 vmid: 'og:url'
              },
              {
                property: 'og:description',
                content: 'Вы можете подключить Stripe к своему сайту самостоятельно, перейдя в личный кабинет.',
                vmid: 'og:description'
              },
              {
                property: 'og:title',
                content: 'Перейдите к подключению Stripe – Easy Payments',
                vmid: 'og:title'
              },
              {
                property: 'og:type',
                content: 'website',
                vmid: 'og:type'
              },

        ],
        link: [
            { rel: 'canonical', href: 'https://easypayments.online/thank-you-account' }
        ],
    },
})
</script>