<template>
  <div>
    <b-row class="mt-4">
      <b-col>
        <b-card class="cardH4" no-body>
          <h2 class="PTextB">{{ $t("SideBar.Instructions") }}</h2>
          <b-row>
            <b-col
              xl="4"
              class="mt-3"
              v-for="(item, index) in getCategories()"
              :key="index"
            >
              <div
                @click="categor(item.slug, item.icon, item.name)"
                style="text-decoration: none"
              >
                <div class="divcardH4 d-flex">
                  <div :class="item.icon"></div>
                  <span class="P1Text ms-2">{{ item.name }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.fetchCategories();
  },
  methods: {
    categor(slug, icon, name) {
      this.setSlug(slug);
      this.setIcon(icon);
      this.setName(name);
      this.fetchCategories2();
    },
    ...mapGetters(["getCategories"]),
    ...mapActions([
      "fetchCategories",
      "setSlug",
      "setIcon",
      "setName",
      "fetchCategories2",
    ]),
  },
};
</script>
