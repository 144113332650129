<template>
  <div style="min-height: calc(100vh - 10px)">
    <b-row style="margin-top: 10px">
      <b-col>
        <b-card class="cardH1" no-body>
          <b-row>
            <b-col lg="7" md="9">
              <h1 class="H1Heading">
                {{ $t("home.h1") }} <span class="Green"></span>
              </h1>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col lg="7" md="9">
              <p class="m-0 PText">Предоставляем комплексные решения для работы на международном рынке.</p>
              <div
                class="d-flex flex-column flex-sm-row align-items-center mt-4 mt-lg-5"
              >
                <router-link to="/plans" class="greenbtn">
                  <b-button class="greenbtn Basisbtnt">{{
                    $t("home.btn1")
                  }}</b-button></router-link
                >
                <router-link to="/balance" class="w-100 ms-sm-3">
                  <b-button
                    class="greenbtn1 mt-3 mt-sm-auto"
                    >Пополнить баланс</b-button
                  ></router-link
                >
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <instructions
      :class="[$i18n.locale == 'En' ? 'margIn' : '']"
    ></instructions>
    <b-row class="mt-4 MBcard" v-if="$i18n.locale == 'ru'">
      <b-col>
        <b-card no-body class="cardH5">
          <div class="d-flex">
            <h2 class="PTextB">Блог</h2>
            <a
              href="https://easypayments.online/blog"
              class="P1Text ms-auto"
              target="_blank"
              >все статьи<img
                src="../../assets/img/cardH5AR.svg"
                class="ms-1"
                alt=""
              />
            </a>
          </div>
          <b-row class="mt-3">
            <b-col md="4">
              <a
                href="https://easypayments.online/blog/kak-oformit-zarubezhnuyu-bankovskuyu-kartu"
                target="_blank"
                style="text-decoration: none"
              >
                <img
                  src="https://easypayments.online//media/articles/img_1703839107.529843.jpg"
                  style="width: 100%; border-radius: 15px"
                  alt=""
                />
                <h3 class="P2Text mt-3">
                  Как гражданину РФ открыть иностранную карту для операций в евро и долларах
                </h3>
              </a>
            </b-col>
            <b-col md="4" class="mt-3 mt-md-0">
              <a
                href="https://easypayments.online/blog/otkrytie-kompanii-dlya-stripe-i-paypal"
                target="_blank"
                style="text-decoration: none"
              >
                <img
                  src="https://easypayments.online//media/articles/img_1709539352.415265.jpg"
                  style="width: 100%; border-radius: 15px"
                  alt=""
                />
                <h3 class="P2Text mt-3">
                  Открытие компании за рубежом для приема международных платежей
                </h3>
              </a>
            </b-col>
            <b-col md="4" class="mt-3 mt-md-0">
              <a
                href="https://easypayments.online/blog/stripe-dlya-ux-ui-biznesa"
                target="_blank"
                style="text-decoration: none"
              >
                <img
                  src="https://easypayments.online//media/articles/img_1703498221.391055.jpg"
                  style="width: 100%; border-radius: 15px"
                  alt=""
                />
                <h3 class="P2Text mt-3">
                  Как начать принимать оплату с помощью Stripe со всего мира?
                </h3>
              </a>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import instructions from "./Instructions.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    instructions,
  },
  data() {
    return {
      accaunt: false,
    };
  },
  methods: {
    ...mapGetters(["getAccounts", "getForms"]),
  },
};
</script>
