import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/style/style.css";
import i18n from "./i18n";
import Clipboard from "v-clipboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);
Vue.component("font-awesome-icon", FontAwesomeIcon);

import smoothscroll from "smoothscroll-polyfill";
window.__forceSmoothScrollPolyfill__ = true;
// kick off the polyfill!
smoothscroll.polyfill();

import VueMeta from "vue-meta";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import VueYandexMetrika from "vue-yandex-metrika";
import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(Clipboard);
Vue.use(VueYandexMetrika, {
  id: 55626226,
  router: router,
  env: "production",
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true,
  },
  // other options
});
import ru from "vee-validate/dist/locale/ru.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
Vue.use(VueMeta, {
  refreshOnceOnNavigation: false,
  attribute: null, // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "",
});
localize("ru", ru);

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
