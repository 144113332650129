<template>
  <div>
    <b-modal id="modal-bank" class="model3" ref="modal-bank" centered hide-footer hide-header title-sr-only:false>
      <div @click="hideModal" style="
          width: 10px;
          height: 10px;
          position: absolute;
          top: 3px;
          right: 10px;
          cursor: pointer;
        ">
        <img src="../../assets/img/krest.svg" alt="" />
      </div>

      <span v-if="getBalance >= price || success">
        <span v-if="!success">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="PTextB">{{ $t("modal3.h2") }}</h2>
            <div class="BalBar1">${{ price }}</div>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <p class="P1Text">{{ $t("modal3.p") }} ${{ price }}</p>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <b-button class="greenbtn" @click="account()">{{
        $t("modal3.btn")
      }}</b-button>
          </div>
        </span>
        <span v-else>
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="PTextB">{{ $t("modalBank.h2") }}</h2>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <p class="P1Text" v-html="$t('modalBank.p1')"></p>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <b-button class="greenbtn" @click="connect()">{{
        $t("modalBank.connect_to_tg")
      }}</b-button>
          </div>
          <div class="d-flex justify-content-center mt-2">
            <b-button class="greenbtn" @click="success = false">{{
        $t("modalBank.btn")
      }}</b-button>
          </div>
        </span>
      </span>
      <span v-else>
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="PTextB">{{ $t("modal3.h2") }}</h2>
          <div class="BalBar1">${{ price }}</div>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <p class="P1Text">{{ $t("modal3.p3") }}</p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-button class="greenbtn" @click="toBalance()">{{
            $t("modal3.btn_balance")
            }} </b-button>
        </div>
      </span>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      success: false,
    };
  },
  mounted() {
    // this.fetchPlans()
    this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
      if (modalId == "modal-bank") {
        if (this.getBalance < this.getPrice) {
          // this.$router.push('/balance')
        }
      }
    });
  },
  computed: {
    ...mapGetters(["getPrice", "getBalance"]),
    price: {
      get() {
        return this.getPrice;
      },
    },
  },
  methods: {
    ...mapActions(["fetchBuyPlan", "setPaidPlanId"]),
    toBalance() {
      this.$router.push('/balance')
    },
    connect() {
      if (localStorage.getItem("locale") == "En") {
        try {
          window.open("https://t.me/ecompayhelp", "_blank").focus();
        } catch {
          window.open("https://t.me/ecompayhelp").focus();
        }
      } else {
        try {
          window.open("https://t.me/ecompayhelp", "_blank").focus();
        } catch {
          window.open("https://t.me/ecompayhelp").focus();
        }
      }
    },
    account() {
      this.fetchBuyPlan()
        .then((response) => {
          if (response.data.status == "success") {
            this.success = true;
          }
        })
        .catch((err) => {
          this.$router.push("/balance");
        });
    },
    hideModal() {
      this.$refs["modal-bank"].hide();
    },
  },
};
</script>
